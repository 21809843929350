//新增资源
const addResource = async (_this,form) => {
    return await _this.http('/galaxyResource/addResource', form, "POST", false);
}
//资源上架/下架
const doUpOrDownResource = async (_this,form) => {
    return await _this.http('/galaxyResource/doUpOrDownResource', form, "POST", false);
}
//资源列表
const getResourcesList = async (_this,productId) => {
    return await _this.http(`/galaxyResource/getResources/${productId}`);
}
//查询资源详情
const getResourceDetail = async (_this,resourceId) => {
    return await _this.http(`/galaxyResource/resourceDetail/${resourceId}`);
}
//更新资源
const updateResource = async (_this,form) => {
    return await _this.http('/galaxyResource/updateResource', form, "POST", false);
}

//通过供应商ids/id获取供应商列表
const getSupplierInfoByOperatorId = async (_this,form) => {
    return await _this.http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', form, "POST", false);
}
//运营商配置地址
const getOperatorAddressByCityIds = async (_this,form) => {
    return await _this.http('/galaxyOperatorAddress/getOperatorAddressByCityIds', form, "POST", false);
}
//通过运营商id查询服务城市id
const doGalaxyGetOperatorServerCity = async (_this,form) => {
    return await _this.http('/galaxyOperator/doGalaxyGetOperatorServerCity/' + form, null, "GET", false);
}
//运营商退款规则
const getOperatorRefundRule = async (_this,form) => {
    return await _this.http('/galaxyOperatorRefundRule/getOperatorRefundRule/' + form, null, "GET", false);
}
//运营商预定规则
const getOperatorReservationNotes = async (_this,form) => {
    return await _this.http('/galaxyOperatorReservationNotes/getOperatorReservationNotes/' + form, null, "GET", false);
}

export default {
    addResource,
    doUpOrDownResource,
    getResourcesList,
    getResourceDetail,
    updateResource,
    getSupplierInfoByOperatorId,
    getOperatorAddressByCityIds,
    doGalaxyGetOperatorServerCity,
    getOperatorRefundRule,
    getOperatorReservationNotes
}
