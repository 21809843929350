<style lang="scss" scoped>
#edit-product-info {
    .product-tab {
        background: #fff;
        border-bottom: 1px solid #DCDFE6;
        height: 40px;
        z-index: 999;
        overflow-y: hidden;

        .tab-content {
            width: 130px;
            height: 40px;
            float: left;

            .tab-button {
                width: 100%;
                color: #909399
            }

            .tab-bottom {
                border: 1px solid #319BF7;
                width: 20px;
                margin: 0 auto;
            }
        }
    }

}
</style>
<template>

    <div id="edit-product-info">
        <el-main style="padding: 0 28px;background: #f8f8f8;min-width: 1056px">
            <div class="product-tab clear">
                <div class="tab-content" @click="selectTab(1)">
                    <el-button type="text" class="tab-button" :style="{color:tabIndex==1?'#000B15':''}">基础信息</el-button>
                    <p class="tab-bottom" v-if="tabIndex==1"></p>
                </div>
                <div class="tab-content" @click="selectTab(2)">
                    <el-button type="text" class="tab-button" :style="{color:tabIndex==2?'#000B15':''}">产品详情</el-button>
                    <p class="tab-bottom" v-if="tabIndex==2"></p>
                </div>
                <div class="tab-content" @click="selectTab(3)">
                    <el-button type="text" class="tab-button" :style="{color:tabIndex==3?'#000B15':''}">预订须知</el-button>
                    <p class="tab-bottom" v-if="tabIndex==3"></p>
                </div>
                <div class="tab-content" @click="selectTab(4)">
                    <el-button type="text" class="tab-button" :style="{color:tabIndex==4?'#000B15':''}">资源列表</el-button>
                    <p class="tab-bottom" v-if="tabIndex==4"></p>
                </div>
                <div class="tab-content" @click="selectTab(5)" v-if="productInfo.productType!=7">
                    <el-button type="text" class="tab-button" :style="{color:tabIndex==5?'#000B15':''}">价格库存</el-button>
                    <p class="tab-bottom" v-if="tabIndex==5"></p>
                </div>
            </div>
        </el-main>
        <!--<div style="overflow: auto;height: calc( 100vh - 243px);">-->
        <keep-alive>
            <router-view class="com-section-has_nav"></router-view>
        </keep-alive>

        <!--</div>-->

        <!--</el-main>-->
    </div>

</template>

<script type="text/ecmascript-6">
import service from '../resourceManage/service';

export default {
    //定义模版数据
    data() {
        return {
            currentTab: 'first'
        }
    },
    components: {},
    //计算属性
    computed: {
        tabIndex() {
            let tabIndex = 1;
            switch (this.$route.name) {
                case 'editbasicInfo':
                    switch (parseInt(this.$route.params.tabIndex)) {
                        case 1:
                            tabIndex = 1;
                            break;
                        case 2:
                            tabIndex = 2;
                            break;
                        case 3:
                            tabIndex = 3;
                            break;
                    }
                    break;
                case 'editresourceManage':
                    tabIndex = 4;
                    break;
                case 'editpriceInventory':
                    tabIndex = 5;
                    break;
                default:
                    tabIndex = 1;
                    break;
            }

            return tabIndex;
        },
        productInfo() {
            let info = this.$store.state.editProduct;
            return info;
        }
    },

    //主件被加载完成
    mounted: function () {

    },
    //定义事件方法
    methods: {
        async selectTab(index) {
            let name = '',
                params = {};
            switch (parseInt(index)) {
                case 1:
                    name = "editbasicInfo";
                    params = {
                        tabIndex: 1,
                        productId: this.productInfo.productId
                    }
                    break;
                case 2:
                    name = "editbasicInfo";
                    params = {
                        tabIndex: 2,
                        productId: this.productInfo.productId
                    }
                    break;
                case 3:
                    name = "editbasicInfo";
                    params = {
                        tabIndex: 3,
                        productId: this.productInfo.productId
                    }
                    break;
                case 4:
                    name = "editresourceManage";
                    params = {
                        productId: this.productInfo.productId,
                        productOperatorId: this.productInfo.productOperatorId,
                        productNo: this.productInfo.productNo,
                        productType: this.productInfo.productType,
                        productForm: this.productInfo.productForm
                    }
                    break;
                case 5:
                    name = "editpriceInventory";
                    params = {
                        productId: this.productInfo.productId,
                        resourceId: this.productInfo.resourceId || -1,
                        productNo: this.productInfo.productNo,
                        productOperatorId: this.productInfo.productOperatorId,
                        productType: this.productInfo.productType,
                        productForm: this.productInfo.productForm
                    }
                    break;
            }
            if (index == 5) {
                const ret = await service.getResourcesList(this, this.productInfo.productId);
                // if(ret.success){
                if (ret.success) {
                    if (ret.data && ret.data.length > 0) {
                        this.sessionStorage.setObject('resources_list', ret.data)
                    }

                }
                if (!ret.data || ret.data.length == 0) {
                    return false;
                }
                // }
            }
            await this.$router.push({
                name: name, params: params
            })
            // await this.$router.replace({
            //     name: name, params: params
            // })
        }
    },
    //监听模版变量
    watch: {}

}
</script>
