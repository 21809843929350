<style lang="scss" scoped>
    #calendar {
        cursor: pointer;
        .date-picker {
            width: 250px;
            height: 32px;
            line-height: 32px;
            padding: 0 10px;
            margin: 0;
        }
        header, .cal-tools div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .info {
                font-size: 12px;
                margin-left: 30px;
                color: #909399;
                line-height: 14px;
            }
            .r-btns {
                display: flex;
                justify-content: center;
            }
            .r-btns button {
                background-color: #fff;
                border: 1px solid #DCDFE6;
                color: #909399;
                width: 80px;
                height: 32px;
                margin: 0;
                padding: 0;
            }
            .r-btns button.active {
                background-color: #1989FA;
                border: 1px solid #1989FA;
                color: #fff;
            }
        }
    }

    .calendar_year_month {
        padding: 15px 0;
        button {
            width: 40px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            text-align: center;
        }
        div {
            color: #1890FF;
            font-size: 18px;
            margin: 0 15px;
        }

    }

    .head-btn-bar {
        button {
            margin: 0;
            width: 80px;
            height: 32px;
            line-height: 32px;
            padding: 0 20px;
            border-color: #DCDFE6;;
            &.e-btn {
                border-radius: 0;
            }
            &.e-btn:first-child {
                margin-left: 30px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &.e-btn.last {
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
            }
            &.e-btn:nth-child(even) {
                border-left: 0;
                border-right: 0;
            }
            &:last-child {
                margin-left: 10px;
            }
        }
    }

    table {
        border-left: 1px solid #F0F2F5;
        width: 100%;
    }

    .calendar_row {
        display: flex;
    }

    .calendar_col {
        /*width: 128px;*/
        /*display: flex;*/
        flex: 1;
        border: 1px solid #F0F2F5;
        /*border-left-color: #fff;*/
        border-right-color: #fff;
        border-top-color: #fff;
        &:last-child {
            border-right-color: #F0F2F5;
        }
        &.selected {
            background: rgba(49, 155, 247, .05);
            /*border: 1px solid #1890FF;*/
            p {
                color: #333;
            }
            & + &.selected {
                border-left-color: #fff;
            }
        }
        &.disabled {
            p, p.last {
                color: #fff;
            }
        }
        &.disabled {
            .day_num, .small-info {
                color: #909399 !important;
            }
        }
    }

    .calendar_day {
        display: flex;
        /*flex: 1;*/
        align-items: center;
        padding: 3px 5px;
        width: 100%;
    }

    .calendar_week {
        color: #909399;
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        background-color: #F8F8F8;
        /*display: flex;*/
        flex: 1;
        text-align: center;
        justify-content: center;
        align-items: center;
        .checkbox {
            margin-left: 5px;
        }
    }

    .calendar_year_month, .head-btn-bar {
        display: flex;
        align-items: center;
    }

    .btn-container {
        display: flex;
        p {
            text-align: left;
        }
        .btn-container-left {
            p {
                height: 15px;
                line-height: 15px;
            }
        }
        div {
            flex: 1;
            /*width: 58px;*/
            flex-grow: 1;
            position: relative;
            height: 75px;
            /*overflow: hidden;*/
            &.no-data > p {
                color: #fff;

                &.last {
                    color: #fff;

                }
            }
            p {
                line-height: 1.4em;
                font-size: 11px;
                -webkit-transform: scale(0.92, 0.92);
                -webkit-text-size-adjust: none;
                font-weight: 300;
                color: #909399;
                &:last-child {
                    color: #1890FF;
                }
            }
            p.day_num {
                font-size: 14px;
                color: #1890FF;
                font-weight: 500;
            }
            p.small-info {
                position: absolute;
                right: -3px;
                font-size: 9px;
                -webkit-transform: scale(0.75, 0.75);
                -webkit-text-size-adjust: none;
                color: #909399;
                & + .small-info {
                    top: 35px;
                    color: orange;
                }
            }
            .checked-icon {
                position: absolute;
                right: -7px;
                bottom: -4px;
                width: 15px;
                height: 15px;
                line-height: 15px;
                background: #319BF7;
                text-align: center;
                overflow: hidden;
                i.el-icon-check {
                    color: #fff;
                    display: inline-block;
                }
            }
        }
        .btn-container-last:last-child {
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                text-align: right;
            }
            .checkbox {
                width: 20px;
                height: 20px;
            }
        }
    }

    .tools_week {
        color: #333;
        font-size: 14px;
        .checkbox {
            margin-right: 5px;
        }
    }
</style>
<style lang="scss">
    .calendar_row {
        .el-button.calendar_day {
            span {
                width: 100%;
            }
        }
    }

</style>
<template>
    <div id="calendar">
        <el-form :model="form" ref="form" :rules="rules">
            <header>
                <div class="cal-tools">
                    <div>
                        <div class="calendar_year_month" v-if="showMode==0">
                            <el-button icon="el-icon-arrow-left" @click="handleMonthClick(-1)"></el-button>
                            <div>{{calendar.year + '年' + calendar.month + '月'}}</div>
                            <el-button icon="el-icon-arrow-right" @click="handleMonthClick(1)"></el-button>
                        </div>
                        <div class="calendar_year_month" v-if="showMode==1">
                            <el-form-item prop="datePicker" style="margin-left: 0">
                                <el-date-picker
                                        class="date-picker"
                                        clearable
                                        v-model="form.datePicker"
                                        :picker-options="datePicker.options"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="起始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="head-btn-bar">
                            <el-button :autofocus="false"
                                       :class="{'e-btn':idx<3,'last':idx===2}"
                                       @click="handleBtnClick(idx)"
                                       v-for="(btn,idx) in btns"
                                       :key="idx">
                                {{btn}}
                            </el-button>
                        </div>
                        <!--<div class="info">-->
                        <!--<p>-->
                        <!--结：结算价，同：同行价-->
                        <!--</p>-->
                        <!--<p>-->
                        <!--直：直客价，市：市场价-->
                        <!--</p>-->
                        <!--</div>-->
                    </div>
                    <div class="tools-week-bar" v-if="showMode==1">
                        <div class="tools_week"
                             v-for="(week,idx) in weeks"
                             :key="idx">
                            <el-checkbox class="checkbox"
                                         @change="handleCheckedWeek(idx)"
                                         v-model="week.checked">
                            </el-checkbox>
                            周{{week.title}}
                        </div>
                    </div>
                </div>
                <div class="r-btns">
                    <button :class="{'active':showMode==0}" @click="changeMode(0)">日历模式</button>
                    <button :class="{'active':showMode==1}" @click="changeMode(1)">批量模式</button>
                </div>
            </header>
            <div v-if="showMode==0">
                <ul class="calendar_row">
                    <li class="calendar_col calendar_week"
                        v-for="(week,idx) in weeks"
                        :key="idx">
                        周{{week.title}}
                        <el-checkbox class="checkbox" @change="handleCheckedWeek(idx)"
                                     v-model="week.checked">
                        </el-checkbox>
                    </li>
                </ul>
                <ul class="calendar_row"
                    v-for="(row,rowIdx) in calendar.days"
                    :key="rowIdx"
                >
                    <li class="calendar_col"
                        :class="{'selected':col.selected,
                        'disabled':col.month!=calendar.month}"
                        v-for="(col,colIdx) in row"
                        :key="colIdx">
                        <el-button type="text"
                                   class="calendar_day"
                                   @click="handleDayClick(col)"
                                   :disabled="col.month!=calendar.month||col.ever">
                            <div class="btn-container">
                                <div class="btn-container-left" :class="{'no-data':!col.inventory}">
                                    <div v-if="col.inventory">
                                        <p>
                                            <span>结：{{subStrData(col.inventory.settlePrice) | currency}}</span>
                                        </p>
                                        <p>
                                            <span>同：{{subStrData(col.inventory.peerPrice) | currency}}</span>
                                        </p>
                                        <p>
                                            <span>直：{{subStrData(col.inventory.customerPrice) | currency}}</span>
                                        </p>
                                        <p>
                                            <span>市：{{subStrData(col.inventory.marketPrice) | currency}}</span>
                                        </p>
                                        <p class="last">
                                            <span>售：{{col.inventory.useInventory}}</span>
                                            <span>/总:{{ col.inventory.allInventory}}</span>
                                        </p>
                                    </div>

                                </div>

                                <div class="btn-container-last">
                                    <div>
                                        <p class="day_num">{{col.day}}</p>
                                        <!--<p class="small-info">-->
                                        <!--{{col.lunar.IDayCn === '初一' ? col.lunar.IMonthCn : col.lunar.IDayCn}}-->
                                        <!--</p>-->
                                        <!--<p class="small-info">{{col.holiday}}</p>-->
                                    </div>
                                    <div class="checked-icon" v-show="col.selected">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </div>
                            </div>
                        </el-button>
                    </li>
                </ul>
            </div>
        </el-form>
    </div>
</template>

<script type="text/ecmascript-6">
    import {initCalendar} from '@/common/js/calendar/'

    export default {
        data() {
            let nowDate = new Date();

            //设置datePicker结束时间
            const str = '1,3,5,7,8,10,12'
            let year = nowDate.getFullYear();
            let mon = nowDate.getMonth() + 7;
            let day = 30;
            if (mon > 12) {
                year += 1;
                mon -= 12;
            }
            if (str.indexOf(mon) > -1)
                day = 31;
            const endTime = +new Date(`${year}/${mon}/${day}`);
            //设置datePicker结束时间

            return {
                nowYear: nowDate.getFullYear(),
                nowMonth: nowDate.getMonth() + 1,
                nowDay: nowDate.getDate(),
                btns: ["全选", "周6、7", "周1-5", "复位"],
                weeks: [{title: '日', checked: false}, {title: '一', checked: false}, {
                    title: '二',
                    checked: false
                }, {title: '三', checked: false}, {title: '四', checked: false}, {
                    title: '五',
                    checked: false
                }, {title: '六', checked: false}],
                calendar: initCalendar(),
                showMode: 0,//0日历模式 1批量模式
                form: {
                    datePicker: null
                },
                rules: {
                    datePicker: [
                        {required: true, message: '请输入日期范围', trigger: 'blur'}
                    ]
                },
                datePicker: {
                    options: {
                        disabledDate(time) {
                            return time.getTime() > endTime;
                        },
                    }
                }
            }
        },
        props: ['inventory'],
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            handleCheckedWeek(idx) {
                let calendar = JSON.parse(JSON.stringify(this.calendar))
                let checked = this.weeks[idx].checked;
                calendar.days.forEach((row) => {
                    row.forEach((day) => {
                        if (day.week === idx && day.month == calendar.month) {
                            day.selected = checked;
                            if (day.month === this.nowMonth && day.day < this.nowDay)
                                day.selected = false;
                        }
                    })
                })
                this.calendar = calendar;
            },
            handleBtnClick(cmd) {
                let calendar = Object.assign({}, this.calendar);
                calendar.days.forEach((row) => {
                    row.forEach((day) => {
                        if (!day.day)
                            return;
                        this.weeksSelected(false);
                        day.selected = false;
                        switch (cmd) {
                            case 0:
                                this.weeksSelected(true)
                                if (day.month === this.calendar.month && !day.ever)
                                    day.selected = true;
                                break;
                            case 1:
                                if ((day.week === 6 || day.week === 0) && day.month === this.calendar.month && !day.ever)
                                    day.selected = true;
                                this.weeks.forEach((week, idx) => {
                                    if (idx === 6 || idx === 0)
                                        week.checked = true
                                })
                                break;
                            case 2:
                                if (day.week != 6 && day.week != 0 && day.month === this.calendar.month && !day.ever)
                                    day.selected = true;
                                this.weeks.forEach((week, idx) => {
                                    if (idx != 6 && idx != 0)
                                        week.checked = true
                                })
                                break;
                            case 3:
                                day.selected = false;
                                break;
                        }
                    })
                })
                this.calendar = calendar;
            },
            handleDayClick(day) {
                if (day.day) {
                    day.selected = !day.selected;
                    this.calendar.days.forEach((row) => {
                        row.forEach((_day) => {
                            if (_day.week === day.week && !_day.selected)
                                this.weeks[_day.week].checked = false
                        })
                    })
                }
                this.calendar = JSON.parse(JSON.stringify(this.calendar))
                this.$emit("dayClick");
            },
            weeksSelected(bool) {
                this.weeks.forEach((data) => {
                    data.checked = bool
                })
            },
            handleMonthClick(cmd) {
                let _month = this.calendar.month + cmd;
                let _year = this.calendar.year;
                if (_month === 0) {
                    _year -= 1;
                    _month = 12
                }
                if (_month === 13) {
                    _month = 1
                    _year += 1

                }
                const date = _year + '/' + _month + '/' + '1'
                this.calendar = initCalendar(date);
                this.weeksSelected(false)
                this.$emit('updateMonth');
            },
            getCalendar() {
                return {
                    type: this.showMode,
                    data: this.showMode == 1 ? this.splitDatePicker() : this.calendar
                };
            },
            splitDatePicker() {
                let dates = [];
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        const dayTime = 24 * 60 * 60 * 1000;
                        let start = this.form.datePicker[0];
                        let end = this.form.datePicker[1];
                        start = +new Date(start);
                        end = +new Date(end);
                        let weeks = '';
                        this.weeks.forEach((data, i) => {
                            if (data.checked)
                                weeks += i
                        })
                        for (let i = start; i < end + dayTime; i += dayTime) {
                            const date = new Date(i);
                            if (!weeks || weeks.indexOf(date.getDay()) > -1)
                                dates.push({
                                    year: date.getFullYear(),
                                    month: date.getMonth() + 1,
                                    day: date.getDate()
                                })
                        }
                    }
                })
                return dates;
            },
            clearSelected() {
                this.handleBtnClick(3);
            },
            subStrData(data) {
                if (!data)
                    return ''
                data = ('' + data);
                return data
            },
            changeMode(idx) {
                if (this.showMode != idx) {
                    this.showMode = idx;
                    if (idx === 0)
                        this.$emit('updateMonth');
                }
            }
        },
        //监听模版变量
        watch: {
            inventory(n) {
                this.calendar.days.forEach((row) => {
                    row.forEach((col) => {
                        delete col.inventory
                        n.forEach((data) => {
                            if (data.inventoryDay == ('' + col.year + (col.month < 10 ? '0' + col.month : col.month) + (col.day < 10 ? '0' + col.day : col.day)))
                                col.inventory = data;
                        })
                    })
                })
                this.calendar = Object.assign({}, this.calendar);
            }
        }

    }
</script>
