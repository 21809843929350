<style lang="scss" scoped>

.resource-list li {
    /*margin-top: 20px;*/
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;

    .el-main {
        padding-top: 0;

        p {
            font-size: 14px;
            color: #333333;
            margin-bottom: 8px;
        }
    }

    &:last-child {
        border-bottom: 0;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /*background-color: #e5e5e5;*/
        padding: 13px 10px;
        /*height: 48px !important;*/

        .btns {
            button {
                padding: 7px 20px;
                border: 1px solid #1890FF;
                color: #1890FF;
                font-size: 14px;
                font-weight: 400;

                &.primary {
                    background-color: #1890FF;
                    color: #fff;
                }

                &.danger {
                    color: #FF3B30;
                    background-color: #FFDAD8;
                    border: 1px solid #FF3B30;
                }
            }
        }
    }
}

.resource-header-info {
    font-size: 14px;
    color: #333;

    span {
        margin-left: 10px;
    }
}

.resource-detail-info {
    display: flex;
    flex: 1;

    & > div {
        flex: 1;
        font-size: 14px;
        color: #333333;

        p {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .notice-btn a {
            color: #1890FF;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.nothing {
    text-align: center;
    margin-top: calc(100vh - 700px);
}

.eTrip-footer-cont {
    text-align: right;
    padding-right: 20px;

    button {
        /*background-color: #fff;*/
        /*color: #1890FF;*/
        font-weight: 400;
        font-size: 14px;
        border-color: #409EFF;
    }
}
</style>
<style lang="scss">
#resource-list {
    .tag {
        height: 22px;
        padding: 0 10px;
        line-height: 22px;
        color: #FF3B30;
        background: #FFEAE9;
        border: 1px solid #FF9690;
        font-size: 12px;
        font-weight: 300;
        border-radius: 2px;

        &.activated {
            color: #72C710;
            background: #F2FFE3;
            border-color: #93D843;
        }
    }

    .footer-height {
        height: calc(100vh - 227px);
    }
}

</style>
<template>
    <div id="resource-list">
        <el-main class="com-eTrip-section section-has-footer">
            <ul v-if="resourceList.length>0" class="resource-list eTrip-section-cont">
                <li v-for="(vm,idx) in resourceList" :key="vm.id">
                    <div
                        style="display: flex;justify-content: space-between;padding: 0 20px;border-bottom: 1px solid #ddd;align-items: center">
                        <div
                            style="display: flex;align-items: center;height: 40px">
                            <p style="border-right: 1px solid #ddd;width: 40px;text-align: center;margin-right: 20px">
                                {{ idx + 1 }}
                            </p>
                            <el-switch v-model="vm.isTop"
                                       active-color="#319bf7"
                                       inactive-color="#999"
                                       :active-text="vm.isTop?'已置顶':'置顶'"
                                       @change="editIndexSave(vm)"></el-switch>
                        </div>
                        <p style="color: #999;font-size: 14px">{{ vm.createTime | dateCus }}</p>
                    </div>
                    <el-header class="header">
                        <div class="resource-header-info">
                            <el-tag class="tag"
                                    :class="{'activated':vm.resourceStatus == 1}"
                                    type="success">
                                {{ vm.resourceStatus == 1 ? '已上架' : '未上架' }}
                            </el-tag>
                            <span style="font-weight: 700">资源ID：{{ vm.resourceNo }}</span>
                            <span
                                style="padding-left: 30px;font-weight: 700">资源类型：{{
                                    formatResourceType(vm.resourceType)
                                }}</span>
                            <span style="padding-left: 30px;">资源供应商名称：{{
                                    vm.resourceSupplierName || '空'
                                }}</span>
                        </div>
                        <div class="btns clear">
                            <el-button class="danger"
                                       :loading="buttons.delBtnDisabled"
                                       :disabled="buttons.delBtnDisabled"
                                       @click="handleDelResource(vm)">删除
                            </el-button>
                            <el-button :loading="buttons.upOrDownDisabled"
                                       :disabled="buttons.upOrDownDisabled"
                                       @click="handleUpOrDownResource(vm)">{{
                                    vm.resourceStatus == 1 ? '下架' : '上架'
                                }}
                            </el-button>
                            <el-button
                                :loading="buttons.addDisabled&&currentId==vm.id"
                                :disabled="buttons.addDisabled"
                                @click="handleEditResource(vm.id)">编辑
                            </el-button>
                            <!--代客下单产品没有价格库存-->
                            <el-button v-if="vm.resourceType!=14&&vm.resourceType!=15" class="primary" type="primary"
                                       @click="handleStockClick(vm.id)">价格库存
                            </el-button>
                        </div>
                    </el-header>
                    <el-main>
                        <p>资源名称：{{ vm.resourceName }}</p>
                        <p v-if="vm.resourceType==12">上车地点：{{ vm.board ? vm.board.join('；') : "-" }}</p>
                        <p v-if="vm.resourceType==12">下车地点：{{ vm.getOff ? vm.getOff.join('；') : "-" }}</p>
                        <div class="resource-detail-info">
                            <div class="l-box">
                                <p v-if="$route.params.productType!=1">
                                    使用天数：{{ vm.resourceUseDay == 999 ? '任意天数' : vm.resourceUseDay }}天</p>
                                <p v-else>计价方式：{{ vm.priceWay == 0 ? ' 按车计价（元/辆）' : '按人计价（元/人）' }}</p>
                                <p v-if="vm.serviceAmount">服务费：¥{{ vm.serviceAmount }}</p>
                                <p v-if="vm.resourceUseCar==1&&vm.resourceType!=11">
                                    车座/车型：{{ formatCarModel(vm.vehicleType).name }}</p>
                                <p v-if="vm.priceWay==1&&$route.params.productType==1">
                                    最低预订人数：{{ vm.minReserveNumberPeople || 1 }}人起订</p>
                                <p class="notice-btn">
                                    <el-button @click="handleNotice(vm.operatorReservationNotesDto.reservationNotes, 1)"
                                               type="text">预订须知
                                    </el-button>
                                    <span style="padding: 0 10px">|</span>
                                    <el-button @click="handleNotice(vm.operatorRefundRuleDto.refundRule, 2)"
                                               type="text">退改规则
                                    </el-button>
                                </p>
                            </div>
                            <div class="r-box">
                                <p>
                                    最晚可预订时间：出发前{{ vm.lastBookDay }}天{{ vm.lastBookHour + ":00" }}时前</p>
                                <p v-if="$route.params.productType==3&&vm.resourceType!=11">
                                    是否用车：{{ vm.resourceUseCar == 0 ? '不用车' : '用车' }}</p>
                                <p v-if="vm.resourceUseCar==1&&$route.params.productType==1&&vm.isNightPrice==1">
                                    夜间费：{{ vm.nightPriceStartDate }}&nbsp;&nbsp;-次日&nbsp;&nbsp;
                                    {{ vm.nightPriceEndDate }}&nbsp;&nbsp;加收&nbsp;&nbsp;
                                    {{ vm.nightPrice }}&nbsp;&nbsp;元夜间费
                                </p>
                                <p v-if="vm.resourceUseCar==1&&$route.params.productType==1&&vm.isNightPrice==0">
                                    夜间费：无夜间费
                                </p>
                                <div v-if="vm.resourceUseCar==1&&$route.params.productType==1&&vm.timeType==0">
                                    <p v-for="(t,index) in vm.timingList" :key="index">
                                        <span v-if="index==0">班次：</span>
                                        <span v-else style="padding-left: 45px"></span>
                                        <span>{{ t[0] }} - {{ t[1] }}，间隔{{ t[2] }}分钟</span>
                                    </p>
                                </div>
                                <p v-if="vm.resourceUseCar==1&&$route.params.productType==1&&vm.timeType==1">
                                    班次：{{ vm.timingList.replace(/"/g, '').replace("[", '').replace("]", '') }}
                                </p>
                                <p v-if="$route.params.productForm==16||$route.params.productForm==17">
                                    购买下限：{{ vm.purchaseNumMin }}</p>
                                <p v-if="$route.params.productForm==16||$route.params.productForm==17">
                                    购买上限：{{ vm.isOpenPurchaseMax == 0 ? '无限制' : vm.purchaseNumMax }}</p>
                            </div>
                        </div>
                    </el-main>
                </li>
            </ul>
            <div class="nothing" v-if="resourceList.length<=0">
                <img src="../../../assets/images/resourceManage/null.png" alt="">
            </div>
            <!--编辑资源-->
            <el-dialog v-loading="dialogFormLoading"
                       custom-class="edit-resource-dialog"
                       :visible.sync="dialogFormVisible"
                       top="0"
                       right="0"
                       title="编辑资源内容"
                       :fullscreen="false"
                       :show-close="false"
                       width="70%"
                       :before-close="onDialogFormClose"
                       :lock-scroll="true">
                <el-form ref="form" :model="form" label-width="180px" :rules="rules" size="small">
                    <el-form-item label="资源ID：" v-if="form.id">
                        {{ form.resourceNo }}&nbsp;&nbsp;
                        <el-tag class="tag"
                                :class="{'activated':form.resourceStatus == 1}"
                                type="success"
                                :closable="false">
                            {{ form.resourceStatus == 1 ? '已上架' : '未上架' }}
                        </el-tag>
                    </el-form-item>
                    <el-form-item label="资源类型：">
                        <p v-if="params.productForm == 16 || params.productForm == 17">
                            {{ params.productForm | productTypeDesc(resourceTypes) }}</p>
                        <el-select v-model="form.resourceType" placeholder="请选择" @change="selectResourceType" v-else>
                            <el-option v-for="item in resourceTypes"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="资源供应商：">
                        <el-select class="resource-supplier"
                                   filterable
                                   clearable
                                   :disabled="resourceSupplierIdDisabled"
                                   v-model="form.resourceSupplierId"
                                   placeholder="请选择">
                            <el-option
                                v-for="item in supplierList"
                                :key="item.id"
                                :label="item.supplierName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="资源名称：" prop="resourceName">
                        <el-input v-model="form.resourceName" placeholder="50 个汉字内" maxlength="50"
                                  style="width: 600px"></el-input>
                    </el-form-item>
                    <div v-if="form.resourceType==12">
                        <el-form-item label="上车地点：" prop="boardLocation">
                            <el-select v-model="form.boardLocation"
                                       placeholder="请选择上车地点，支持定点栅栏/固定点/机场/站点"
                                       style="width: 600px" multiple filterable>
                                <el-option v-for="item in operatorAddressList" :value="item.id" :key="item.id"
                                           :label="(item.addressType==1?'[栅栏] ':'[固定点] ') + item.addressDirection"></el-option>
                            </el-select>
                            <el-tooltip class="item" effect="light" content="若需添加上车地点请联系总部管理员"
                                        placement="top-end">
                                <i class="icon icon-wenhao"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="下车地点：" prop="getOffLocation">
                            <el-select v-model="form.getOffLocation"
                                       placeholder="请选择下车地点，支持定点栅栏/固定点/机场/站点"
                                       style="width: 600px" multiple filterable>
                                <el-option v-for="item in operatorAddressList" :value="item.id" :key="item.id"
                                           :label="(item.addressType==1?'[栅栏] ':'[固定点] ') + item.addressDirection"></el-option>
                            </el-select>
                            <el-tooltip class="item" effect="light" content="若需添加下车地点请联系总部管理员"
                                        placement="bottom-end">
                                <i class="icon icon-wenhao"></i>
                            </el-tooltip>
                        </el-form-item>
                    </div>

                    <div v-if="form.resourceType == 3">

                        <el-form-item label="上车地点：">
                            <el-select v-model="form.boardLocation"
                                       placeholder="请选择上车地点，支持定点栅栏/固定点/机场/站点"
                                       style="width: 600px" multiple filterable>
                                <el-option v-for="item in operatorAddressList" :value="item.id" :key="item.id"
                                           :label="(item.addressType==1?'[栅栏] ':'[固定点] ') + item.addressDirection"></el-option>
                            </el-select>
                            <el-tooltip class="item" effect="light" content="若需添加上车地点请联系总部管理员"
                                        placement="top-end">
                                <i class="icon icon-wenhao"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item label="下车地点：">
                            <el-select v-model="form.getOffLocation"
                                       placeholder="请选择下车地点，支持定点栅栏/固定点/机场/站点"
                                       style="width: 600px" multiple filterable>
                                <el-option v-for="item in operatorAddressList" :value="item.id" :key="item.id"
                                           :label="(item.addressType==1?'[栅栏] ':'[固定点] ') + item.addressDirection"></el-option>
                            </el-select>
                            <el-tooltip class="item" effect="light" content="若需添加下车地点请联系总部管理员"
                                        placement="bottom-end">
                                <i class="icon icon-wenhao"></i>
                            </el-tooltip>
                        </el-form-item>
                    </div>
                    <el-form-item label="是否用车：" v-if="$route.params.productType==3&&form.resourceType!=11">

                        <p v-if="form.resourceType == 11 || params.productForm == 16 || params.productForm == 17">
                            非用车</p>
                        <el-radio-group v-model="form.resourceUseCar" @change="selectUserCar" v-else>
                            <el-radio-button label="1">
                                用车
                            </el-radio-button>
                            <el-radio-button label="0" v-if="form.resourceType!=21">
                                非用车
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="使用天数：" v-if="$route.params.productType!=1">
                        <p v-if="params.productForm == 16">{{ form.resourceUseDay }}</p>
                        <el-select v-model="form.resourceUseDay" v-else>
                            <el-option :value="0.5"></el-option>
                            <el-option v-for="val in 20" :value="val" :key="val"></el-option>
                            <el-option label="任意天数" :value="999"></el-option>
                        </el-select>
                    </el-form-item>
                    <!--一日游或多日游-->
                    <el-form-item label="预订是否需要实名：" v-if="params.productForm == 16||params.productForm == 17">
                        <el-switch
                            v-model="form.isRealName"
                            active-color="#319bf7"
                            inactive-color="#999">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="服务费：" prop="serviceAmount" v-if="$route.params.productType==7">
                        <el-input type="number" v-model="form.serviceAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="计价方式：" v-if="$route.params.productType==1">
                        <el-radio-group v-model="form.priceWay" @change="getSeats(form.priceWay)">
                            <el-radio-button label="0" v-if="form.resourceUseCar==1">
                                按车计价（元/辆）
                            </el-radio-button>
                            <el-radio-button label="1">
                                按人计价（元/人）
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="车座/车型：" v-if="form.resourceUseCar==1&&form.resourceType!=11">
                        <seat-model v-model="form.vehicleType" :price-way="form.priceWay"></seat-model>
                    </el-form-item>
                    <el-form-item label="最低起订人数：" prop="minReserveNumberPeople"
                                  v-if="form.priceWay==1&&$route.params.productType==1">
                        <el-input-number v-model="form.minReserveNumberPeople"
                                         :min="1" :max="carMaxNum"
                                         label="描述文字">
                        </el-input-number>
                        &nbsp;&nbsp;人起订&nbsp;&nbsp;
                    </el-form-item>
                    <el-form-item label="最晚可预订时间：">
                        出发前&nbsp;&nbsp;
                        <el-input-number v-model="form.lastBookDay"
                                         :min="0" :max="20"
                                         label="描述文字">
                        </el-input-number>
                        &nbsp;&nbsp;天&nbsp;&nbsp;
                        <el-select v-model="form.lastBookHour" placeholder="请选择">
                            <el-option v-for="item in (0,23)"
                                       :key="item"
                                       :label="(item<10?'0'+item:item)+':00'"
                                       :value="(item<10?'0'+item:(item+''))">
                            </el-option>
                        </el-select>
                        &nbsp;&nbsp;时前
                    </el-form-item>
                    <el-form-item label="班次：" v-if="form.resourceUseCar==1&&$route.params.productType==1">
                        <el-radio-group v-model="form.timeType">
                            <el-radio-button label="0">
                                时间段
                            </el-radio-button>
                            <el-radio-button label="1">
                                离散点
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="form.timeType==0&&$route.params.productType==1">
                        <div v-for="(period,index) in form.periodList" :key="index" style="display: inline-block">
                            <div style="float: left">
                                <el-form-item :prop="'periodList[' + index + '].start'"
                                              :rules="{required: true, message: '请选择时间', trigger: 'change'
                                }">
                                    <el-time-select
                                        v-model="period.start" style="width: 100px"
                                        :picker-options="{
                                            start: '00:00',
                                            step: '00:30',
                                            end: '23:30'
                                            }"
                                        placeholder="选择时间">
                                    </el-time-select>&nbsp;&nbsp;一&nbsp;&nbsp;
                                </el-form-item>
                            </div>
                            <div style="float: left">
                                <el-form-item :prop="'periodList[' + index + '].end'"
                                              :rules="{
                                required: true, message: '请选择时间', trigger: 'change'
                                }">
                                    <el-time-select
                                        v-model="period.end" style="width: 100px"
                                        :picker-options="{
                                            start: '00:30',
                                            step: '00:30',
                                            end: '24:00'
                                            }"
                                        placeholder="选择时间">
                                    </el-time-select>
                                    ，间隔&nbsp;&nbsp;
                                </el-form-item>
                            </div>
                            <div style="float: left">
                                <el-form-item :prop="'periodList[' + index + '].step'"
                                              :rules="{
                                required: true, message: '请填写间隔', trigger: 'change'
                                }">
                                    &nbsp;&nbsp;
                                    <el-input style="width: 80px" v-model="period.step"></el-input>&nbsp;&nbsp;分钟
                                </el-form-item>
                            </div>
                            <div style="float: left">
                                &nbsp;&nbsp;<el-button @click="delPeriod(index)" v-if="form.periodList.length>1"
                                                       type="danger" plain>删除
                            </el-button>
                                &nbsp;&nbsp;<el-button @click="addPeriod" v-if="index==(form.periodList.length-1)"
                                                       type="primary">添加
                            </el-button>
                            </div>
                        </div>

                    </el-form-item>
                    <el-form-item v-if="form.timeType==1&&$route.params.productType==1" prop="timingList">
                        <el-select v-model="form.timingList" multiple placeholder="选择时间" filterable
                                   style="width: 320px">
                            <el-option
                                v-for="item in timeList"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="夜间费：" v-if="form.resourceUseCar==1&&$route.params.productType==1">
                        <el-radio-group v-model="form.isNightPrice">
                            <el-radio-button label="0">
                                无
                            </el-radio-button>
                            <el-radio-button label="1">
                                有
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="form.isNightPrice==1&&$route.params.productType==1">
                        <el-form-item style="float: left" prop="nightPriceStartDate">
                            <el-time-select style="width: 100px"
                                            v-model="form.nightPriceStartDate"
                                            :picker-options="{
                                start: '12:00',
                                step: '01:00',
                                end: '23:00'
                                }"
                                            placeholder="时间">
                            </el-time-select>
                            &nbsp;&nbsp;-&nbsp;次日&nbsp;&nbsp;
                        </el-form-item>
                        <el-form-item style="float: left" prop="nightPriceEndDate">
                            <el-time-select style="width: 100px"
                                            v-model="form.nightPriceEndDate"
                                            :picker-options="{
                                start: '00:00',
                                step: '01:00',
                                end: '12:00'
                                }"
                                            placeholder="时间">
                            </el-time-select>
                            &nbsp;&nbsp;加收&nbsp;&nbsp;
                        </el-form-item>
                        <el-form-item style="float: left" prop="nightPrice">
                            <el-input style="width: 80px" v-model="form.nightPrice" type="number"></el-input>&nbsp;&nbsp;元夜间费
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="购买下限：" v-if="params.productForm == 16||params.productForm == 17">
                        <el-input-number v-model="form.purchaseNumMin" :min="1"></el-input-number>
                    </el-form-item>
                    <el-form-item label="购买上限：" v-if="params.productForm == 16||params.productForm == 17">
                        <el-input-number v-if="!form.isOpenPurchaseMax"
                                         v-model="form.purchaseNumMax"
                                         :min="form.purchaseNumMin"></el-input-number>
                        <el-checkbox style="margin-left: 10px" v-model="form.isOpenPurchaseMax">无限制</el-checkbox>
                    </el-form-item>
                    <el-form-item label="预订规则：" prop="reservationNotesId"
                                  v-if="!['16','17','21'].includes(params.productForm)">
                        <el-select v-model="form.reservationNotesId" placeholder="请选择【预订规则】"
                                   style="width: 488px">
                            <el-option v-for="item in operatorReservationNotes" :value="item.id" :key="item.id"
                                       :label="item.reservationNotesName"></el-option>
                        </el-select>
                        <span @click="handleNotice(form.reservationNotesId, 3)" class="notice-btn">
                            <a href="javascript:void(0)" style="color: #319BF7">&nbsp;&nbsp;预览</a>
                        </span>
                        <el-tooltip class="item" effect="light" content="如需添加【预订须知】模板请联系总部管理员"
                                    placement="top-end">
                            <i class="icon icon-wenhao" style="color: #319BF7"></i>
                        </el-tooltip>
                    </el-form-item>
                    <el-form-item label="退改规则：" prop="refundRuleId"
                                  v-if="!['16','17','21'].includes(params.productForm)">
                        <el-select v-model="form.refundRuleId" placeholder="请选择【退改规则】" style="width: 488px">
                            <el-option v-for="item in operatorRefundRule" :value="item.id" :key="item.id"
                                       :label="item.refundRuleName"></el-option>
                        </el-select>
                        <span @click="handleNotice(form.refundRuleId, 4)" class="notice-btn">
                            <a href="javascript:void(0)" style="color: #319BF7">&nbsp;&nbsp;预览</a>
                        </span>
                        <el-tooltip class="item" effect="light" content="如需添加【退改规则】模板请联系总部管理员"
                                    placement="bottom-end">
                            <i class="icon icon-wenhao" style="color: #319BF7"></i>
                        </el-tooltip>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <p>{{ form.resourceNo ? '编辑资源' : '添加资源' }}</p>
                    <el-button type="primary" @click="handleSubmit">确 定</el-button>
                </div>
            </el-dialog>

            <!--预定须知-->
            <el-dialog
                :title="title"
                :visible.sync="noticeVisible"
                width="50%">
                <div v-html="noticeContent" style="line-height: 1.8em"></div>
            </el-dialog>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <el-button type="primary"
                           :loading="buttons.addDisabled"
                           :disabled="buttons.addDisabled"
                           icon="el-icon-plus"
                           @click="handleAddClick"
                >添加资源
                </el-button>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
import resourceService from './service'
import {localSearch} from '@/common/js/gaodeMap/index';
import SeatModel from "@/components/seatModel/index";
import {getSeatModel, resourceTypeList} from "@/data/index";

export default {
    //定义模版数据
    data() {
        let timeList = [];
        const minList = ['00', '10', '20', '30', '40', '50'];
        for (let i = 0; i < 24; i++) {
            minList.forEach(m => {
                let t = i + ':' + m;
                if (i < 10) t = '0' + t;
                timeList.push(t);
            })
        }
        return {
            value: '',
            timeList: timeList,
            options: [],
            resourceList: [],
            resourceTypes: resourceTypeList,
            noticeContent: "",
            params: {
                productId: "",
                operatorId: "",
                productNo: ""
            },
            startAddress: '',
            resourceSupplierIdDisabled: false,
            supplierList: [],
            dialogFormVisible: false,
            noticeVisible: false,
            addressList: [],
            initForm: {
                resourceSupplierId: '',
                resourceName: null,
                resourceUseCar: 1,
                resourceType: null,
                resourceUseDay: 1,
                vehicleType: 1,
                lastBookDay: 1,
                serviceAmount: 0,
                lastBookHour: '16',
                timeType: 0,
                priceWay: '0',
                startAddress: '',
                timingList: [],
                isNightPrice: '0',
                nightPriceStartDate: '22:00',
                nightPriceEndDate: '06:00',
                nightPrice: 30,
                minReserveNumberPeople: 1,
                periodList: [{start: '06:00', end: '20:00', step: '15'}],
                boardLocation: [],
                getOffLocation: [],
                isRealName: false,
                purchaseNumMin: 1,
                purchaseNumMax: 1,
                isOpenPurchaseMax: true
                // boardFenceList: [],
            },
            form: {},
            rules: {
                resourceName: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value)
                                callback("填写资源名称")
                            else
                                callback();
                        }, trigger: 'blur'

                    }
                ],
                // boardFenceList: [{
                //     required: true,
                //     validator: (rule, value, callback) => {
                //         if (value && value.length > 0) {
                //             callback();
                //         } else {
                //             callback('请选择栅栏')
                //         }
                //     }, trigger: 'change'
                // }],
                // boardFenceList: [{required: true, message: '请选择下车地点', trigger: 'change'}],
                boardLocation: [{required: true, message: '请选择上车地点', trigger: 'change'}],
                serviceAmount: [{required: true, message: '请输入服务费', trigger: 'change'}],
                getOffLocation: [{required: true, message: '请选择下车地点', trigger: 'change'}],
                timingList: [{
                    validator: (rule, value, callback) => {
                        if (!value || value.length == 0)
                            callback("请选择离散点时间")
                        else
                            callback();
                    }, trigger: 'change'

                }],
                nightPriceStartDate: [{required: true, message: '请选择夜间开始时间', trigger: 'change'}],
                nightPriceEndDate: [{required: true, message: '请选择夜间结束时间', trigger: 'change'}],
                nightPrice: [{required: true, message: '请填写夜间费', trigger: 'change'}],
                reservationNotesId: [{required: true, message: '请选择预订规则', trigger: 'change'}],
                refundRuleId: [{required: true, message: '请选择退改规则', trigger: 'change'}],
            },
            dialogFormLoading: false,
            buttons: {
                currentId: null,
                addDisabled: false,
                upOrDownDisabled: false,
                delBtnDisabled: false
            },
            operatorServerCity: [],
            operatorAddressList: [],
            operatorRefundRule: [],
            operatorReservationNotes: [],
            title: '预订须知：',
            currentId: null,
            carMaxNum: 52
        }
    },
    components: {SeatModel},
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    //主件被加载完成
    activated: function () {
        this.params.productId = this.$route.params.productId;
        this.params.operatorId = this.$route.params.productOperatorId;
        this.params.productNo = this.$route.params.productNo;
        this.params.productType = this.$route.params.productType;
        this.params.productForm = this.$route.params.productForm;
        let productType = this.$route.params.productType;
        let resourceTypes = [];
        this.resourceTypes.forEach(item => {
            if (item.productType == productType) {
                resourceTypes.push(item)
            }
        })
        this.resourceTypes = resourceTypes;
        if (this.$route.meta.title == "新增产品") {
            this.$store.dispatch("addProduct", {
                productId: this.params.productId,
                resourceId: -1,
                productOperatorId: this.params.operatorId,
                productNo: this.params.productNo,
                productType: this.$route.params.productType,
                productForm: this.$route.params.productForm
            });
        } else {
            this.$store.dispatch("editProduct", {
                productId: this.params.productId,
                resourceId: -1,
                productOperatorId: this.params.operatorId,
                productNo: this.params.productNo,
                productType: this.$route.params.productType,
                productForm: this.$route.params.productForm
            });
        }
        this.doGalaxyGetOperatorServerCity();
        this.getOperatorRefundRule();
        this.getOperatorReservationNotes();
        this.getResourceList();
        this.resetForm();
    },
    //定义事件方法
    methods: {
        resetForm() {
            let form = JSON.parse(JSON.stringify(this.initForm));
            let {
                productForm
            } = this.params
            if (form.resourceType == 11 || productForm == 16 || productForm == 17)
                form.resourceUseCar = 0;
            if (productForm == 16) form.resourceUseDay = 1
            if (productForm == 16 || productForm == 17)
                form.resourceType = productForm
            this.form = {};
            for (let key in form) {
                this.$set(this.form, key, form[key]);
            }
        },
        remoteMethod(query, cb) {
            if (query) {
                this.loading = true;
                let _this = this;
                this.localList = [];
                // this.$nextTick(function () {
                console.log(query);
                localSearch(query).then(function (res) {
                    console.log(res);
                    if (res) {
                        _this.localList = res;
                        _this.loading = false;
                    }
                    cb(_this.localList);
                })
                // })
            } else {
                console.log('err');
                this.localList = [];
                // cb(this.localList);
            }
        },
        handleSelect(item) {
            console.log(item);
            this.form.startAddress = item;
        },
        //获取资源列表
        async getResourceList() {
            const ret = await resourceService.getResourcesList(this, this.params.productId);
            if (ret.success)
                this.resourceList = ret.data;
            if (ret.data && ret.data.length > 0) {
                ret.data.sort((a, b) => {
                    if ((a.isTop == 1 && b.isTop == 1) || (a.isTop != 1 && b.isTop != 1))
                        return a.createTime - b.createTime ? -1 : 1
                    if (a.isTop == 1)
                        return -1
                    if (b.isTop == 1)
                        return 1
                }).forEach(item => {
                    item.isTop = item.isTop == 1;
                    if (item.resourceType == 12) {
                        item.board = [];
                        item.getOff = [];
                        item.resourceAddressRspDtos.forEach(address => {
                            let addressInfo = (address.operatorAddressDto.addressType == 1 ? '[栅栏]' : '[固定点]') + address.operatorAddressDto.addressDirection;
                            if (address.addressType == 1 && address.operatorAddressDto) {
                                item.board.push(addressInfo)
                            }

                            if (address.addressType == 2 && address.operatorAddressDto)
                                item.getOff.push(addressInfo);
                        })
                    }
                    if (item.resourceUseCar == 1 && this.$route.params.productType == 1 && item.timeType == 0) {
                        item.timingList = JSON.parse(item.timingList);
                        let periodList = [];
                        item.timingList.forEach(i => {
                            i = i.split(',');
                            periodList.push(i);
                        })
                        item.timingList = periodList;
                    }
                })
            }

        },
        //获取供应商列表
        async getSupplierInfoByOperatorId() {
            if (this.userInfo.userType === '1') {
                this.supplierList = [{
                    id: this.userInfo.refId,
                    supplierName: this.userInfo.companyName
                }];
                this.form.resourceSupplierId = this.userInfo.refId;
                this.resourceSupplierIdDisabled = true;
                return true;
            } else {
                const ret = await resourceService.getSupplierInfoByOperatorId(this, {operatorId: this.params.operatorId})
                if (ret.success) {
                    this.supplierList = ret.data;
                    return true;
                } else
                    this.buttons.addDisabled = false;
            }
        },
        selectResourceType(val) {
            this.form.resourceUseCar = val == 11 ? 0 : 1;
        },
        selectUserCar() {
            this.form.priceWay = this.form.resourceUseCar == 1 ? '0' : '1';
            this.form.resourceType = this.resourceTypes[0].value;
        },
        handleStockClick(id) {
            this.sessionStorage.setObject('resources_list', this.resourceList)
            if (this.$route.meta.title == "新增产品")
                this.$router.push({path: `/addproduct/priceInventory/${this.params.productId}/${this.params.productNo}/${id}/${this.$route.params.productType}/${this.$route.params.productForm}`})
            else
                this.$router.push({path: `/editproduct/priceInventory/${this.params.productId}/${this.params.productNo}/${this.params.operatorId}/${id}/${this.$route.params.productType}/${this.$route.params.productForm}`})
        },
        handleSubmit() {
            this.$refs['form'].validate((valid) => {
                console.log(valid, this.form);
                if (valid) {
                    this.$confirm('确定保存资源吗?', '退出编辑', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.dialogFormLoading = true;
                        if (!this.form.id)
                            this.addSave();
                        else
                            this.editSave();
                        this.$refs['form'].resetFields();
                    }).catch(() => {
                    });
                }
            })

        },
        formatLocation(list, type) {
            let data = [];
            this.operatorAddressList.forEach(item => {
                list.forEach(id => {
                    if (item.id == id) {
                        data.push({
                            addressType: type,
                            addressId: item.addressId
                        })
                    }
                })
            })
            return data;
        },
        formatData() {
            let priceWay = this.form.priceWay;
            if ([16, 17].includes(this.form.resourceType))
                priceWay = '1'
            if (this.form.resourceType === 21)
                priceWay = '0'
            let form = {
                resourceName: this.form.resourceName,
                resourceUseCar: this.form.resourceUseCar,
                resourceType: this.form.resourceType,
                lastBookDay: this.form.lastBookDay,
                lastBookHour: this.form.lastBookHour,
                priceWay,
                reservationNotesId: this.form.reservationNotesId,
                refundRuleId: this.form.refundRuleId,
                productId: this.params.productId,
                productNo: this.params.productNo,
                purchaseNumMax: this.form.purchaseNumMax,
                purchaseNumMin: this.form.purchaseNumMin,
                serviceAmount: this.form.serviceAmount,
                isRealName: this.form.isRealName ? 1 : 0,
                isOpenPurchaseMax: this.form.isOpenPurchaseMax ? 0 : 1
            }
            if (this.form.resourceSupplierId) form.resourceSupplierId = this.form.resourceSupplierId;
            if (this.$route.params.productType == 1) {
                if (this.form.priceWay == 1) {
                    form.minReserveNumberPeople = this.form.minReserveNumberPeople;
                }
                form.isNightPrice = this.form.isNightPrice;
                form.timeType = this.form.timeType;
                form.timingList = JSON.stringify(this.form.timingList);
                if (this.form.timeType == 0) {
                    let periodList = [];
                    this.form.periodList.forEach(item => {
                        let period = item.start + ',' + item.end + ',' + item.step;
                        periodList.push(period);
                    })
                    form.timingList = JSON.stringify(periodList);
                }
            } else {
                form.resourceUseDay = this.form.resourceUseDay;
            }
            if (this.$route.params.productType == 1 || this.$route.params.productType == 2) {
                if (this.form.resourceType == 3 || this.form.resourceType == 12) {
                    // let boardFence = this.formatLocation(this.form.boardFenceList, 1);
                    // form.resourceAddressDtos = boardFence;
                    let board = [], getOff = [];
                    if (this.form.boardLocation) board = this.formatLocation(this.form.boardLocation, 1);
                    if (this.form.getOffLocation) getOff = this.formatLocation(this.form.getOffLocation, 2);
                    console.log(board);
                    form.resourceAddressDtos = board.concat(getOff);
                }
            }
            if (this.form.resourceUseCar == 1) {
                form.vehicleType = this.form.vehicleType;
                // form.carModel = this.form.carModel;
            }
            if (this.form.isNightPrice == 1) {
                form.nightPriceStartDate = this.form.nightPriceStartDate;
                form.nightPriceEndDate = this.form.nightPriceEndDate;
                form.nightPrice = this.form.nightPrice;
            }
            return form;
        },
        async addSave() {
            let form = this.formatData();
            console.log(form);
            const ret = await
                resourceService.addResource(this, form);
            if (ret.success) {
                this.$message.success("资源保存成功！");
                this.dialogFormVisible = false;
                this.resetForm();
                this.getResourceList();
            } else
                this.$message.error("抱歉，资源保存失败！")
            this.dialogFormLoading = false;

        },
        async editSave() {
            let form = this.formatData();
            form.id = this.form.id;
            this.dialogFormLoading = true;
            const ret = await this.saveUpdate(form)
            this.dialogFormLoading = false;
            if (ret === true) {
                this.$message.success("修改成功！")
            } else this.$message.error(ret)
        },
        //修改置顶
        async editIndexSave(vm) {
            const ret = await this.saveUpdate({
                id: vm.id,
                isTop: vm.isTop ? 1 : 0,
                productId: vm.productId,
                resourceType: vm.resourceType,
                resourceAddressDtos: vm.resourceAddressDtos
            });
            if (ret === true) {
                this.$message.success("置顶成功！")
            } else {
                vm.isTop = false;
                this.$message.error(ret)
            }
        },
        //保存修改
        async saveUpdate(form) {
            const ret = await resourceService.updateResource(this, form);
            if (ret.success) {
                this.dialogFormVisible = false;
                this.resetForm();
                this.getResourceList();
                return true
            } else return ret.errors[0].message
        },
        handleCancel() {
            this.dialogFormVisible = false;
        },
        handleNotice(content, type) {
            this.title = type == 1 ? '预订须知：' : '退改规则：';
            if (type == 3) {
                this.operatorReservationNotes.forEach(item => {
                    if (content == item.id) {
                        content = item.reservationNotes;
                    }
                })
                this.title = '预订须知：';
            }
            if (type == 4) {
                this.operatorRefundRule.forEach(item => {
                    if (content == item.id) {
                        content = item.refundRule;
                    }
                })
                this.title = '退改规则：';
            }
            this.noticeContent = content.replace(/\n/g, '<br/>');

            this.noticeVisible = true
        },
        async handleAddClick() {
            this.$set(this.buttons, 'addDisabled', true);
            this.resetForm();
            this.resourceSupplierIdDisabled = false;
            await this.getSupplierInfoByOperatorId();
            this.dialogFormVisible = true;
            this.$set(this.buttons, 'addDisabled', false);
        },
        async handleEditResource(resourceId) {
            this.currentId = resourceId;
            this.buttons.addDisabled = true;
            // this.resetForm();
            const ret = await resourceService.getResourceDetail(this, resourceId);
            if (ret.success) {
                this.form = ret.data;
                for (let key in ret.data) {
                    this.$set(this.form, key, ret.data[key]);
                }
                this.$set(this.form, 'periodList', [{start: '06:00', end: '20:00', step: '15'}]);
                this.$set(this.form, 'isRealName', this.form.isRealName === 1);
                this.$set(this.form, 'isOpenPurchaseMax', this.form.isOpenPurchaseMax === 0);
                this.$set(this.form, 'boardLocation', []);
                this.$set(this.form, 'getOffLocation', []);
                if (ret.data.resourceType == 12 || ret.data.resourceType == 3) {
                    ret.data.resourceAddressRspDtos.forEach(item => {
                        if (item.addressType == 1 && item.operatorAddressDto) {
                            this.form.boardLocation.push(item.operatorAddressDto.id);
                        }
                        if (item.addressType == 2 && item.operatorAddressDto) {
                            this.form.getOffLocation.push(item.operatorAddressDto.id);
                        }
                    })
                }
                if (this.form.timingList) {
                    this.form.timingList = JSON.parse(this.form.timingList);
                    if (this.form.timeType == 0) {
                        this.$set(this.form, 'periodList', []);
                        this.form.timingList.forEach(item => {
                            let period = {};
                            item = item.split(',');
                            period.start = item[0];
                            period.end = item[1];
                            period.step = item[2];
                            this.form.periodList.push(period);
                        })
                        this.form.timingList = [];
                    }
                }
                if (ret.data.operatorReservationNotesDto)
                    this.form.reservationNotesId = ret.data.operatorReservationNotesDto.id;
                if (ret.data.operatorRefundRuleDto)
                    this.form.refundRuleId = ret.data.operatorRefundRuleDto.id;
                this.form.vehicleType = this.form.vehicleType ? parseInt(this.form.vehicleType) : 1;
                await this.getSupplierInfoByOperatorId();
                this.dialogFormVisible = true
            }
            this.buttons.addDisabled = false;

        },
        async onDialogFormClose() {
            try {
                await this.$confirm('资源还未保存，是否退出?', '退出编辑', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.dialogFormVisible = false;
            } catch (e) {
                console.log(e);
            }
        },
        //上下架
        async handleUpOrDownResource(vm) {
            const status = vm.resourceStatus == 1 ? 0 : 1;
            this.$confirm(`确定${status == 1 ? '上架' : '下架'}该资源?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.buttons.upOrDownDisabled = true;
                const ret = await resourceService.doUpOrDownResource(this, {id: vm.id, resourceStatus: status});
                if (ret.success) {
                    vm.resourceStatus = status;
                    this.$message.success(`${status == 1 ? '上架' : '下架'}成功!`)
                } else
                    this.$message.error('抱歉，操作失败！')
                this.buttons.upOrDownDisabled = false;
            }).catch(() => {
            });
        },
        formatResourceType(index) {
            let type = this.resourceTypes.find(data => {
                return data.value === index
            });
            if (type)
                return type.name;
            else return '-'
        },
        //删除
        handleDelResource(vm) {
            this.$confirm('确认删除资源吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let form = Object.assign({}, vm);
                form.isDelete = 1;
                form.isTop = form.isTop ? 1 : 0;
                form.timingList = JSON.stringify(form.timingList);
                const ret = await resourceService.updateResource(this, form);
                if (ret.success) {
                    this.$message.success('资源删除成功！')
                    this.dialogFormVisible = false;
                    this.resetForm();
                    this.getResourceList();
                } else
                    this.$message.error('资源删除失败！')
            }).catch(() => {

            });
        },

        getSeats(val) {
            if (val == 1) this.form.vehicleType = 14;
            else this.form.vehicleType = 1;
        },
        //运营商配置地址
        async getOperatorAddress(data) {
            const ret = await resourceService.getOperatorAddressByCityIds(this, data);
            if (ret.success)
                this.operatorAddressList = ret.data;
        },
        //通过运营商id查询服务城市id
        async doGalaxyGetOperatorServerCity() {
            const ret = await resourceService.doGalaxyGetOperatorServerCity(this, this.$route.params.productOperatorId);
            console.log('通过运营商id查询服务城市id', ret);
            if (ret.success)
                // this.operatorServerCity = ret.data;
                this.getOperatorAddress(ret.data);
        },
        //运营商退款规则
        async getOperatorRefundRule() {
            const ret = await resourceService.getOperatorRefundRule(this, this.$route.params.productOperatorId);
            if (ret.success)
                this.operatorRefundRule = ret.data;
        },
        //运营商预定规则
        async getOperatorReservationNotes() {
            const ret = await resourceService.getOperatorReservationNotes(this, this.$route.params.productOperatorId);
            if (ret.success)
                this.operatorReservationNotes = ret.data;
        },
        addPeriod() {//添加时间段
            let period = {start: '', end: '', step: ''};
            this.form.periodList.push(period);
        },
        delPeriod(idx) {//删除时间段
            this.form.periodList.splice(idx, 1);
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
    }
}
</script>

