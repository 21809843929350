<style lang="scss" scoped>
.new-add-product {
    /*min-width: 1180px;*/
    .box-card {
        margin-bottom: 10px;

        .el-card__body {
            padding: 15px 20px;
        }

        &.is-always-shadow {
            box-shadow: none;
            border-radius: 0;
        }
    }

    .product-type {
        margin-right: 10px;
        float: left;
        margin-bottom: 10px;
        /*display: inline-block;*/
        .product-btn {
            height: 30px;
            width: 110px;
            border: 1px solid #DCDFE6;
            /*text-align: center;*/
            padding-left: 20px;
            cursor: pointer;
            float: left;
            line-height: 30px;
            border-radius: 4px;
            margin-right: -1px;
            margin-top: 1px;
        }

        .product-btn-item {
            border-radius: 4px 0 0 4px !important;
        }

        .product-select {
            float: left;
            width: 160px;
            /*line-height: 30px;*/
            /*margin-top: 5px;*/
            /*select {*/
            /*height: 32px;*/
            /*border-radius: 0 4px 4px 0;*/
            /*border: 1px solid #DCDFE6;*/
            /*width: 160px;*/
            /*}*/
        }
    }

    .image-upload {
        min-height: 80px;
        width: 958px;
        border-radius: 2px;
        border: 1px solid #DCDFE6;
        padding: 20px 0;
    }

    .back319 {
        background: #319BF7;
        border-color: #319BF7 !important;
        color: #fff;
        /*margin-top: 4px !important;*/
    }

    .border319 {
        border-color: #319BF7 !important;
    }
}

#product-add {

    .product-shelf {
        width: 130px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        border-radius: 4px;
        text-align: center;
        margin-right: 20px;
        float: left;
    }

    .no-Shelf {
        background: #FFEAE9;
        border: 1px solid #FFABA6;
        color: #FF3B30;
    }

    .been-shelf {
        background: #F2FFE3;
        border: 1px solid #93D843;
        color: #72C710;
    }

    .online {
        background: #E6F1FC;
        border: 1px solid #A3D0FD;
        color: #319BF7;
    }

    .product-title {
        margin-bottom: 10px;
    }

    .product-subtitle {
        margin: 10px 0;
    }
}

</style>
<style lang="scss">
.product-select {
    .el-input__inner {
        border-radius: 0 4px 4px 0 !important;
    }
}

.new-add-product {
    .box-card {
        .el-card__body {
            padding: 15px 20px;
        }

        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

}
</style>
<template>

    <div id="product-add">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div v-show="tabIndex==1" class="new-add-product">
                    <el-form ref="rule" label-position="right" label-width="135px"
                             :model="form" :rules="ruleForm" size="small">
                        <el-card class="box-card" v-if="form.productNo">
                            <el-form-item label="产品ID：" style="margin: -8px 0 5px 0">
                                <span>{{ form.productNo || '—' }}</span>
                            </el-form-item>
                            <el-form-item>
                                <div class="product-shelf no-Shelf" v-if="form.productStatus==0">未上架</div>
                                <div class="product-shelf been-shelf" v-if="form.productStatus==1">已上架</div>
                                <div class="product-shelf online"
                                     v-if="form.productStatus==1&&form.productOnlineChannel[0] =='1'">TDS
                                </div>
                                <div class="product-shelf online"
                                     v-if="form.productStatus==1&&form.productOnlineChannel[1]=='1'">游客端
                                </div>
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card">
                            <el-row>
                                <el-col>
                                    <el-form-item label="出发地：" style="float: left;"
                                                  prop="startCity">
                                        <el-select v-model="form.startCity" :disabled="productInfo.productId?true:false"
                                                   filterable v-if="!productInfo.productId"
                                                   placeholder="请选择出发地城市" @change="selectStartCity">
                                            <el-option
                                                v-for="(item,index) in serverCityList"
                                                :key="index"
                                                :label="item.name"
                                                :value="index">
                                            </el-option>
                                        </el-select>
                                        <span v-else>{{ form.startCityName }}</span>
                                    </el-form-item>
                                    <el-form-item label="—" label-width="60px" style="float: left;">
                                    </el-form-item>
                                    <el-form-item label="目的地：" style="float: left;" label-width="90px"
                                                  prop="destCity">
                                        <div style="width: 180px">
                                            <el-select v-model="form.destCity" @change="selectDestCity"
                                                       v-if="!productInfo.productId"
                                                       filterable :disabled="productInfo.productId?true:false"
                                                       placeholder="请选择目的地城市">
                                                <el-option
                                                    v-for="(item,index) in serverCityList"
                                                    :key="index"
                                                    :label="item.name"
                                                    :value="index">
                                                </el-option>
                                            </el-select>
                                            <span v-else>{{ form.destCityName }}</span>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="产品类型/形态：" prop="productForm" style="margin-bottom: 0">
                                <div v-for="(item,index) in productTypeListData"
                                     :key="item.value"
                                     class="product-type clear"
                                     @click="selectType(item,index)">
                                    <div
                                        :class="{'back319': item.value == form.productType, 'product-btn-item': item.item&&item.item.length>0}"
                                        class="product-btn">{{ item.label }}
                                    </div>
                                    <div class="product-select" v-if="item.value == form.productType">
                                        <el-select v-model="form.productForm" size="small"
                                                   :disabled="productInfo.productId?true:false"
                                                   filterable
                                                   placeholder="请选择" @change="selectProductForm">
                                            <el-option
                                                v-for="(item1,index) in item.item"
                                                :key="index"
                                                :label="item1.itemLabel"
                                                :value="item1.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="product-select" v-else>
                                        <el-select size="small" v-model="disabled" :disabled="true"
                                                   filterable
                                                   placeholder="请选择">
                                        </el-select>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="所属运营商名称：" prop="productOperatorId">
                                <div style="width: 380px" v-if="userInfo.userType == 0">
                                    <el-select v-model="form.productOperatorId" :disabled="userInfo.userType!=0"
                                               filterable
                                               no-match-text="运营商不存在"
                                               placeholder="同营业执照上的公司名称" style="width: 380px">
                                        <el-option
                                            v-for="(item,index) in operatorList"
                                            :key="index"
                                            :label="item.companyName"
                                            :value="item.refId">
                                        </el-option>
                                    </el-select>
                                </div>
                                <span v-else>{{ operatorName }}</span>
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card" v-if="showDetail">
                            <el-form-item label="产品名称：" prop="productName">
                                <div style="width: 958px">
                                    <el-input placeholder="请输入产品名称" v-model="form.productName"
                                              maxlength="100"></el-input>
                                </div>
                            </el-form-item>
                            <div v-if="form.productType==2&&['3','21'].includes(form.productForm)">
                                <el-form-item label="线路行程：" prop="roadWay" style="margin-bottom: 10px">
                                    <div style="width: 958px">
                                        <el-input
                                            style="font-size: 14px"
                                            type="textarea"
                                            :rows="5"
                                            resize="none"
                                            placeholder="请输入线路行程"
                                            v-model="form.roadWay">
                                        </el-input>
                                    </div>
                                </el-form-item>
                            </div>
                            <el-form-item label="确认方式：" prop="confirmType" style="margin-bottom: 0">
                                <el-radio-group v-model="form.confirmType">
                                    <el-radio :label="1">立即确认</el-radio>
                                    <el-radio :label="2">二次确认</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item :label="siteName"
                                          v-if="form.productType==1&&form.productForm!=12&&(form.startCity>=0||form.destCity>=0)"
                                          prop="selectSite">
                                <el-checkbox-group v-model="form.selectSite" @change="formatSite">
                                    <el-checkbox :label="index" v-for="(site,index) in this.cityList" :checked="checked"
                                                 @change="checked=!checked"
                                                 :disabled="(form.productForm==7||form.productForm==8)&&airportCode!=''&&site.airportCode!=airportCode"
                                                 :key="site.id">
                                        {{ site.stationName }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <!--<el-form-item label="送机站点：" v-if="form.productType==1&&(form.productForm==8||form.productForm==10)&&form.destCity" prop="sendSite">-->
                            <!--<el-checkbox-group v-model="form.sendSite" @change="formatSendSite">-->
                            <!--<el-checkbox :label="index" v-for="(site,index) in this.destCityList" :disabled="form.productForm==8&&airportCode!=''&&site.airportCode!=airportCode"-->
                            <!--:key="site.id">{{site.stationName}}</el-checkbox>-->
                            <!--</el-checkbox-group>-->
                            <!--</el-form-item>-->
                            <el-form-item label="封面图片：" prop="imageList"
                                          v-if="form.productType!=1&&form.productForm!=2&&form.productType!=7">
                                <p style="color: #909399;margin-bottom: 10px">● 可上传图片尺寸大于等于440*248的图片，包含png、jpg格式；●
                                    可批量上传1至6张；● 点击预览图上的按钮可“剪裁”和“删除”。</p>
                                <div class="image-upload">
                                    <image-upload @update:imageUploadCompleted="imageUpload" :max="6" is-cropper
                                                  height="78px" width="140px" :images="form.imageList"></image-upload>
                                </div>

                            </el-form-item>
                            <el-form-item label="亮点推荐："
                                          v-if="form.productType!=1&&form.productForm!=2&&form.productType!=7">
                                <div style="width: 540px;margin-bottom: 10px">
                                    <el-input placeholder="亮点1，不超过15字" maxlength="15"
                                              v-model="form.highlightOne"></el-input>
                                </div>
                                <div style="width: 540px;margin-bottom: 10px">
                                    <el-input placeholder="亮点2，不超过15字" maxlength="15"
                                              v-model="form.highlightTwo"></el-input>
                                </div>
                                <div style="width: 540px">
                                    <el-input placeholder="亮点3，不超过15字" maxlength="15"
                                              v-model="form.highlightThree"></el-input>
                                </div>
                            </el-form-item>
                        </el-card>
                        <el-card class="box-card" style="margin-bottom: 0" v-if="showDetail">
                            <el-row>
                                <el-col>
                                    <el-form-item label="订单联系人：" style="width: 320px;float: left"
                                                  prop="orderContractName">
                                        <div>
                                            <el-input placeholder="姓名" maxlength="30"
                                                      v-model="form.orderContractName"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label-width="10px" style="float: left" prop="orderContractPhone">
                                        <div style="width: 180px">
                                            <el-input placeholder="手机号" maxlength="11"
                                                      v-model="form.orderContractPhone"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label-width="10px" style="float: left" prop="orderContractMail">
                                        <div style="width: 280px">
                                            <el-input placeholder="邮箱" v-model="form.orderContractMail"></el-input>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col>
                                    <el-form-item label="产品联系人：" style="width: 320px;float: left;margin-bottom: 0"
                                                  prop="productContractName">
                                        <div>
                                            <el-input placeholder="姓名" maxlength="30"

                                                      v-model="form.productContractName"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label-width="10px" style="float: left;margin-bottom: 0"
                                                  prop="productContractPhone">
                                        <div style="width: 180px">
                                            <el-input placeholder="手机号" maxlength="11"
                                                      v-model="form.productContractPhone"></el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label-width="10px" style="float: left;margin-bottom: 0"
                                                  prop="productContractMail">
                                        <div style="width: 280px">
                                            <el-input placeholder="邮箱" v-model="form.productContractMail"></el-input>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-form>
                </div>
                <div v-show="tabIndex==2" style="padding: 20px;background: #fff">
                    <tinymce v-if="tabIndex==2" id="product-info-tinymce" :height="600"
                             v-model="form.productDetail"></tinymce>
                </div>
                <div v-show="tabIndex==3" style="padding: 20px;background: #fff">
                    <div v-if="['16','17','21'].includes(form.productForm)">
                        <h4 class="product-title" style="margin-bottom: 10px">费用说明</h4>
                        <p class="product-subtitle">费用包含：</p>
                        <tinymce v-if="tabIndex==3" id="fees-description-contain" :height="300"
                                 v-model="form.costInclude"></tinymce>
                        <p class="product-subtitle">费用不包含：</p>
                        <tinymce v-if="tabIndex==3" id="fees-description-not-contain" :height="300"
                                 v-model="form.costNotInclude"></tinymce>
                        <ul>
                            <li style="display: flex;width: 998px">
                                <div
                                    style="width: 560px;flex: none">
                                    <p class="product-subtitle">取消规则</p>
                                    <el-table
                                        :data="getRefundRules"
                                        border>
                                        <el-table-column
                                            fixed
                                            label="取消时间">
                                            <template slot-scope="scope">
                                                <p v-if="scope.row.type==='custom'">{{ scope.row.title }}</p>
                                                <div v-else style="display: flex; align-items: center">
                                                    使用日期前
                                                    <el-input v-model="scope.row.day" :disabled="scope.row.disabled"
                                                              style="width: 70px;margin: 0 5px"
                                                              @input="handleInputRules"
                                                              size="small"></el-input>
                                                    天
                                                    <date-time
                                                        style="width: 100px;margin: 0 5px"
                                                        :timeMinuteStep="['00', '30']"
                                                        isTitle
                                                        heightBox="32"
                                                        placeholder="开始时间"
                                                        :defaultHour="scope.row.hours"
                                                        :defaultMinute="scope.row.minutes"
                                                        :indexIdent="{index: scope.$index}"
                                                        :disabled="scope.row.disabled"
                                                        @onUpdate="changeDateHour"
                                                    >

                                                        <!--                                            -->
                                                    </date-time>
                                                    <span>{{ scope.row.type | formatDesc(containTypeList) }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="name"
                                            label="取消费"
                                            width="100">
                                            <template slot-scope="scope">
                                                <div v-if="scope.row.type!=='custom'"
                                                     style="display: flex;align-items: center">
                                                    <el-input v-model="scope.row.cancellationRate" size="small"
                                                              maxlength="3"
                                                              type="number"
                                                              @input="handleRate(scope.$index)"
                                                              style="margin-right: 5px"></el-input>
                                                    %
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            fixed="right"
                                            label="操作"
                                            width="80">
                                            <template slot-scope="scope">
                                                <el-switch v-model="form.isCancelHoliday"
                                                           v-if="scope.row.type==='custom'"></el-switch>
                                                <el-button @click="changeDelRules(scope.$index)"
                                                           v-if="scope.row.isDelete"
                                                           type="text" size="small">删除
                                                </el-button>
                                                <!--                                    <el-button type="text" size="small">编辑</el-button>-->
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                </div>
                                <div
                                    style="width:calc(100% - 580px);margin-left: 20px">

                                    <p class="product-subtitle">取消规则预览</p>
                                    <el-table
                                        :data="form.refundRules"
                                        border>
                                        <el-table-column
                                            fixed
                                            label="取消时间">
                                            <template slot-scope="scope">
                                                <div style="display: flex;height: 32px; align-items: center">
                                                    使用日期
                                                    <span v-if="scope.row.day == 0">当天</span>
                                                    <span v-else>前{{ scope.row.day }}天</span>
                                                    {{ scope.row.hour }}
                                                    {{ scope.row.type | formatDesc(containTypeList) }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="name"
                                            label="取消费"
                                            width="100">
                                            <template slot-scope="scope">
                                                <div style="display: flex;align-items: center">
                                                    {{ scope.row.cancellationRate + '%' }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </li>
                            <li>
                                <div style="width: 560px;text-align: center;margin: 10px"
                                     v-if="form.refundRules && form.refundRules.length < 5">
                                    <el-button @click="changeAddRules" type="primary" size="small">新增一条规则
                                    </el-button>
                                </div>
                            </li>


                        </ul>
                    </div>

                    <h4 class="product-title">预订须知</h4>
                    <tinymce v-if="tabIndex==3" id="book-notice-tinymce" :height="300"
                             v-model="form.bookInfo"></tinymce>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div style="float: right" v-if="tabIndex==1||tabIndex==2||tabIndex==3">
                    <el-button type="primary" v-if="!isAdd" style="width: 120px" @click="validForm(1)">保存</el-button>
                    <el-button type="primary" v-else style="width: 120px" @click="validForm(2)">保存</el-button>
                </div>
            </div>
        </el-footer>
    </div>

</template>

<script type="text/ecmascript-6">
import tinymce from '../../../components/tinymce-vue/index.vue';
import {onlyChineseAndLetter, isCellphone, uniq} from '@/common/js/Utils';
import ImageUpload from '@/components/imageUpload';
import {getCityData} from '@/urls/index';
import dateTime from '@/components/timePickerWithTitle/index.vue'

const containTypeList = [
    {value: 0, label: '之前(含)'},
    {value: 1, label: '之后'},
]

export default {
    //定义模版数据
    data() {
        let validateName = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写姓名'));
            } else if (!onlyChineseAndLetter(value)) {
                callback(new Error('姓名格式错误'));
            } else {
                callback();
            }
        };
        let validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写手机号'));
            } else if (!isCellphone(value)) {
                callback(new Error('手机号格式错误'));
            } else {
                callback();
            }
        };
        let form = {
                productOperatorId: '',
                productForm: '',
                productName: '',
                roadWay: '',
                startCity: '',
                destCity: '',
                productType: '',
                selectSite: [],
                // sendSite: [],
                confirmType: 2,
                imageList: [],
                productContractName: '',
                productContractPhone: '',
                productContractMail: '',
                orderContractName: '',
                orderContractPhone: '',
                orderContractMail: '',
                highlightOne: '',
                highlightTwo: '',
                highlightThree: '',
                productDetail: '',
                costNotInclude: '',
                costInclude: '',
                refundRules: [
                    {
                        day: 0,
                        hour: '00:00',
                        hours: '00',
                        minutes: '00',
                        type: 0,
                        cancellationRate: '0',
                        isDelete: false,
                        disabled: false
                    },
                    {
                        day: 0,
                        hour: '00:00',
                        hours: '00',
                        minutes: '00',
                        type: 1,
                        cancellationRate: '100',
                        isDelete: false,
                        disabled: true
                    }
                ],
                productNo: '',
                productOnlineChannel: '',
                productStatus: 0,
                libraryOperatorAddressId: '',
                fenceList: [],
            },
            ruleForm = {
                productOperatorId: {required: true, message: '请选择所属运营商', trigger: 'change'},
                productForm: {required: true, message: '请选择产品类型/形态', trigger: 'blur'},
                productName: {required: true, message: '请输入产品名称', trigger: 'blur'},
                roadWay: {required: true, message: '请输入线路行程', trigger: 'blur'},
                startCity: {required: true, message: '请选择出发地', trigger: 'change'},
                destCity: {required: true, message: '请选择目的地', trigger: 'change'},
                confirmType: {required: true, message: '请选择确认方式', trigger: 'change'},
                imageList: {required: true, message: '请选择封面图片', trigger: 'change'},
                selectSite: {required: true, message: '请选择机场站点', trigger: 'change'},
                // sendSite: {required: true, message: '请选择送机站点', trigger: 'change'},
                productContractName: [{required: true, message: '请填写姓名', trigger: 'blur'},
                    {validator: validateName, trigger: 'blur'}],
                productContractPhone: {validator: validatePhone, trigger: 'blur'},
                productContractMail: [
                    {required: true, message: '请输入邮箱地址', trigger: 'blur'},
                    {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                ],
                orderContractName: [{required: true, message: '请填写姓名', trigger: 'blur'},
                    {validator: validateName, trigger: 'blur'}],
                orderContractPhone: {validator: validatePhone, trigger: 'blur'},
                orderContractMail: [
                    {required: true, message: '请输入邮箱地址', trigger: 'blur'},
                    {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                ]
            },
            productTypeList = [
                {
                    label: '接送服务',
                    value: "1",
                    item: [
                        {itemLabel: '接机', value: "7"}, {itemLabel: '送机', value: "8"},
                        {itemLabel: '接站', value: "9"}, {itemLabel: '送站', value: "10"},
                        {itemLabel: '接送服务', value: "12"}],
                    isSelect: false
                },
                {
                    label: '旅游包车',
                    value: "2",
                    item: [
                        {itemLabel: '按日包车', value: "2"},
                        {itemLabel: '线路包车', value: "3"}
                    ],
                    isSelect: false
                },
                {
                    label: '旅游线路',
                    value: 3,
                    item: [{itemLabel: '精致小团', value: "4"}, {itemLabel: '车+X', value: "5"},
                        {itemLabel: '特色体验', value: "6"}, {itemLabel: '门票', value: "11"},
                        {itemLabel: '一日游', value: "16"}, {itemLabel: '多日游', value: "17"}, {
                            itemLabel: '包车游',
                            value: "21"
                        }
                    ],
                    isSelect: false
                },
                {
                    label: '即时预约',
                    value: 7,
                    item: [
                        {itemLabel: '接送服务', value: "14"},
                        {itemLabel: '按日包车', value: "15"}
                    ],
                    notOperableUserType: '1'
                }
            ],
            cityList = [],
            isAdd = false,
            airportCode = '',
            disabled = '',
            operatorList = [],
            serverCityList = [],
            siteName = '接机站点：',
            checked = false,
            showDetail = false,
            operatorName = '';
        let imgNameList = ['posOne', 'posTwo', 'posThree', 'posFour', 'posFive', 'posSix']
        return {
            form,
            productTypeList,
            cityList,
            isAdd,
            ruleForm,
            airportCode,
            disabled,
            operatorList,
            serverCityList,
            siteName,
            checked,
            showDetail,
            operatorName,
            imgNameList,
            addressByMerchantList: [],
            addressList: [],
            containTypeList,
        }
    },
    components: {
        tinymce,
        ImageUpload,
        dateTime
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        tabIndex() {
            return this.$route.params.tabIndex;
        },
        productInfo() {
            let info = this.$store.state.addProduct;
            if (this.$route.meta.title == "编辑产品") {
                info = {productId: this.$route.params.productId}
            }
            return info;
        },
        productTypeListData() {
            return this.productTypeList.filter(({notOperableUserType}) => notOperableUserType !== this.userInfo.userType)
        },
        getRefundRules() {
            return [...this.form.refundRules, {
                title: '法定节假日订单一经确认不可取消',
                type: 'custom'
            }]
        }
    },
    //主件被加载完成
    async activated() {
        if (this.userInfo.userType == 0) {
            this.getOperator();
        }
        this.getServerCity();
        if (this.productInfo.productId)
            this.getProductDetail();
        if (this.userInfo.userType != 0 &&
            this.$route.meta.title == '新增产品') {
            if (this.userInfo.userType != 1) {
                this.operatorName = this.userInfo.companyName;
                this.form.productOperatorId = this.userInfo.refId;
            } else this.getOperatorId();
        }
        if (!this.productInfo.productId) {
            const productInfo = await this.sessionStorage.getObject("product-info")
            if (productInfo) {
                await this.$store.dispatch("addProduct", productInfo);
                this.getProductDetail();
            }
        }
    },
    //定义事件方法
    methods: {
        imageUpload(img) {
            this.form.imageList = img;
            let imgList = {}
            let newImgList = []
            if (this.form.imageList && this.form.imageList.length > 0) {
                this.form.imageList.forEach(item => {
                    if (item.imageUrl) newImgList.push(item)
                })
                newImgList.forEach((img, index) => {
                    imgList[this.imgNameList[index]] = img.imageUrl
                })
                this.form.productCoverImage = imgList
            }
        },
        async getOperator() {//获取运营商list
            let res = await this.http('/galaxyLoginuserApi/getAdminLoginUserOrgInfoByType', {refType: '2'}, 'POST');
            console.log(res);
            if (res && res.success) {
                this.operatorList = res.data;
            }

        },
        async getServerCity() {
            const ret = await getCityData(this, '2');
            // console.log(ret, '================');
            // return ret;
            if (ret)
                this.serverCityList = ret;
        },
        selectType(item, idx) {
            if (this.productInfo.productId) return false;
            this.productTypeList[idx].isSelect = true;
            this.form.productType = item.value;
            this.form.productForm = item.item[0].value;
            this.showDetail = true;
            this.$store.dispatch("addProduct", {
                ...this.$store.state.addProduct,
                productType: item.value
            });
            this.selectProductForm();
        },
        selectProductForm() {
            if (this.form.productForm == 7 || this.form.productForm == 9) {
                this.siteName = '接机/站点：';
                console.log(this.form.startCity);
                if (this.form.startCity === 0 || this.form.startCity)
                    this.getSite(this.serverCityList[this.form.startCity].shortCode);
            }
            if (this.form.productForm == 8 || this.form.productForm == 10) {
                this.siteName = '送机/站点：';
                if (this.form.destCity === 0 || this.form.destCity)
                    this.getSite(this.serverCityList[this.form.destCity].shortCode);
            }
        },
        async getOperatorId() {
            let operatorId = '';
            let res = await this.http('/galaxyOperator/getBySupplierId/' + this.userInfo.refId);
            console.log(res);
            if (res.success) {
                operatorId = res.data.operatorId;
                this.operatorName = res.data.operatorName;
                this.form.productOperatorId = operatorId;
            } else {
                this.messageError(res.errors[0].message)
            }
        },
        async getSite(shortCode, siteLists) {
            let stationType = 2;
            if (this.form.productForm == 9 || this.form.productForm == 10) stationType = 1;
            let form = {
                shortCode: shortCode,
                stationType: stationType
            };
            try {
                let res = await this.http('/galaxyStationApi/doStationList', form, 'POST');
                if (res.success) {
                    let sites = [];
                    let siteList = [];
                    this.airportCode = '';
                    console.log(siteLists);
                    if (res.data.length > 0) {
                        res.data.forEach((item, index) => {
                            let site = {
                                "cityId": item.cityId,
                                "cityName": item.cityName,
                                "stationId": item.id,
                                "stationName": item.stationName,
                                // "isdisabled": false,
                                "airportCode": item.airportCode
                            }
                            siteList.push(site);
                            this.cityList = siteList;
                            if (siteLists) {
                                this.airportCode = siteLists[0].airportCode;
                                siteLists.forEach(pick => {
                                    if (pick.stationId == item.id) {
                                        // console.log(index);
                                        sites.push(index);
                                    }
                                })
                            }
                        })
                        this.checked = false;
                        this.$set(this.form, 'selectSite', uniq(sites))
                    } else {
                        this.cityList = [];
                        this.messageError('该城市没有站点');
                    }
                } else {
                    this.messageError(res.errors[0].message);
                }
            } catch (e) {
                this.messageError();
            }
        },
        selectStartCity(val) {
            this.form.startCityName = this.serverCityList[val].name;
            this.form.startCityId = this.serverCityList[val].shortCode;
            if (this.form.productForm == 7 || this.form.productForm == 9) {
                this.getSite(this.serverCityList[val].shortCode);
            }
            console.log(this.form.startCityId);

            this.getAddressFence(this.form.startCityId)
        },
        //根据城市ids获取城市下的地理围栏
        async getAddressFence(cityId) {
            let res = await this.http('/galaxyStationApi/getOperatorAddressByCityIds', [cityId], 'POST');
            if (res && res.success) {
                this.addressList = res.data;
                // this.addressByMerchantList = res.data
            }

        },
        departureOperator(type, data) {
            let list = [];
            data.forEach(item => {
                let data = {
                    libraryOperatorAddressId: item,
                    operatorAddressType: type
                }
                list.push(data)
            })
            switch (type) {
                case 1:
                    this.form.fenceList = list;
                    break;
                // case 2:
                //     this.form.quoteRuleRelProductDto.destinationOperatorAddressList = list;
                //     break
            }
        },  // 服务区域
        selectDestCity(val) {
            this.form.destCityName = this.serverCityList[val].name;
            this.form.destCityId = this.serverCityList[val].shortCode;
            if (this.form.productForm == 8 || this.form.productForm == 10) {
                this.getSite(this.serverCityList[val].shortCode);
            }
        },
        formatSite(val) {
            console.log(val);
            if (val && val.length > 0 && this.form.productForm == 7) {
                this.airportCode = this.cityList[val[0]].airportCode;
            } else {
                this.airportCode = '';
            }
        },
        formatSendSite(val) {
            if (val && val.length > 0 && this.form.productForm == 8) {
                this.airportCode = this.destCityList[val[0]].airportCode;
            } else {
                this.airportCode = '';
            }
        },
        validForm(type) {
            this.$refs.rule.validate((valid) => {
                if (valid) {
                    let form = {
                        confirmType: this.form.confirmType,
                        destCityId: this.form.destCityId,
                        destCityName: this.form.destCityName,
                        highlightRecommend: {},
                        orderContractMail: this.form.orderContractMail,
                        orderContractName: this.form.orderContractName,
                        orderContractPhone: this.form.orderContractPhone,
                        pickSite: [],
                        productContractMail: this.form.productContractMail,
                        productContractName: this.form.productContractName,
                        productContractPhone: this.form.productContractPhone,
                        productCoverImage: {},
                        productForm: this.form.productForm,
                        productName: this.form.productName,
                        productOperatorId: this.form.productOperatorId,
                        productType: this.form.productType,
                        sendSite: [],
                        startCityId: this.form.startCityId,
                        startCityName: this.form.startCityName,
                        productContentDto: {},
                        isCancelHoliday: this.form.isCancelHoliday ? 1 : 0
                    }
                    if (this.form.productForm == 7 || this.form.productForm == 9) {
                        this.cityList.forEach((item, index) => {
                            this.form.selectSite.forEach((item1) => {
                                if (index == item1) form.pickSite.push(item);
                            })
                        })
                    }
                    if (this.form.productForm == 8 || this.form.productForm == 10) {
                        this.cityList.forEach((item, index) => {
                            this.form.selectSite.forEach((item1) => {
                                if (index == item1) form.sendSite.push(item);
                            })
                        })
                    }
                    if (['3', '21'].includes(this.form.productForm)) {
                        form.roadWay = this.form.roadWay
                    }
                    this.form.productCoverImage && (form.productCoverImage = this.form.productCoverImage);
                    this.form.highlightOne && (form.highlightRecommend.highlightOne = this.form.highlightOne);
                    this.form.highlightTwo && (form.highlightRecommend.highlightTwo = this.form.highlightTwo);
                    this.form.highlightThree && (form.highlightRecommend.highlightThree = this.form.highlightThree);
                    this.form.productDetail && (form.productContentDto.productDetail = this.form.productDetail);
                    this.form.costInclude && (form.costInclude = this.form.costInclude);
                    this.form.costNotInclude && (form.costNotInclude = this.form.costNotInclude);
                    this.form.refundRules && (form.refundRules = JSON.stringify(this.form.refundRules));
                    this.form.bookInfo && (form.productContentDto.bookInfo = this.form.bookInfo);
                    this.$confirm('确认保存产品吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        if (type == 1)
                            this.save(form);
                        if (type == 2)
                            this.edit(form);
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消保存'
                        });
                    });
                } else {
                    this.$message.error('信息不全，请填写完整')
                    return false;
                }
            })
        },
        async save(form) {
            try {
                let res = await this.http('/galaxyProduct/productPost', form, 'POST');
                if (res.success) {
                    this.isAdd = true;
                    const productInfo = {
                        productId: res.data.id,
                        resourceId: -1,
                        productOperatorId: res.data.productOperatorId,
                        productNo: res.data.productNo,
                        productType: res.data.productType,
                        productForm: res.data.productForm
                    };
                    this.$store.dispatch("addProduct", productInfo);
                    this.sessionStorage.setObject("product-info", productInfo);
                    this.$message.success('保存成功');
                    let tabIndex = this.$route.params.tabIndex;
                    if (tabIndex < 3) {
                        this.$router.replace({name: "basicInfo", params: {tabIndex: (parseInt(tabIndex) + 1)}})
                    } else {
                        this.$router.replace({
                            name: "resourceManage",
                            params: {
                                productId: res.data.id,
                                productOperatorId: res.data.productOperatorId,
                                productNo: res.data.productNo,
                                productType: res.data.productType,
                                productForm: res.data.productForm
                            }
                        })
                    }

                } else {
                    this.messageError(res.errors[0].message);
                }
            } catch (e) {
                this.messageError();
            }
        },
        async getProductDetail() {
            this.loadingShow();
            let res = await this.http('/galaxyProduct/productGet/' + this.productInfo.productId);
            this.loadingHide();
            if (res.success) {
                this.isAdd = true;
                let dataForm = res.data
                for (let key in res.data) {
                    this.$set(dataForm, key, res.data[key]);
                }
                this.showDetail = true;
                this.operatorName = res.data.productOperatorName;
                res.data.highlightRecommend.highlightOne && (dataForm.highlightOne = res.data.highlightRecommend.highlightOne);
                res.data.highlightRecommend.highlightTwo && (dataForm.highlightTwo = res.data.highlightRecommend.highlightTwo);
                res.data.highlightRecommend.highlightThree && (dataForm.highlightThree = res.data.highlightRecommend.highlightThree);
                res.data.productContentDto && res.data.productContentDto.productDetail && (dataForm.productDetail = res.data.productContentDto.productDetail);
                res.data.costInclude && (dataForm.costInclude = res.data.costInclude);
                res.data.costNotInclude && (dataForm.costNotInclude = res.data.costNotInclude);
                res.data.refundRules && (dataForm.refundRules = JSON.parse(res.data.refundRules));
                res.data.isCancelHoliday = res.data.isCancelHoliday === 1
                res.data.productContentDto && res.data.productContentDto.bookInfo && (dataForm.bookInfo = res.data.productContentDto.bookInfo);

                this.form = dataForm
                if (this.form.productType != 1 && this.form.productForm != 2 && this.form.productForm != 14 && this.form.productForm != 15) {
                    this.$set(this.form, 'imageList', []);
                    if (res.data.productCoverImage) {
                        let productCoverImages = res.data.productCoverImage;
                        for (let key in productCoverImages) {
                            let productImage = {
                                imageUrl: productCoverImages[key],
                                showImage: productCoverImages[key] ? true : false
                            }
                            console.log(productCoverImages[key], 'productCoverImages[key]----------productCoverImages[key]');
                            if (productCoverImages[key]) {
                                this.form.imageList.push(productImage);
                            }
                        }
                        if (this.form.imageList.length < 6) {
                            if (this.form.imageList[0].imageUrl) {
                                this.form.imageList.splice(0, 0, {imageUrl: '', showImage: false})
                            }
                        }
                    }
                }
                let cities = this.serverCityList;
                cities.forEach((item, index) => {
                    if (item.shortCode == this.form.startCityId) {
                        this.form.startCity = index;
                    }
                    if (item.shortCode == this.form.destCityId) {
                        this.form.destCity = index;
                    }
                })
                if (this.form.productForm == 7 || this.form.productForm == 9) {
                    this.getSite(res.data.startCityId, res.data.pickSite);
                }
                if (this.form.productForm == 8 || this.form.productForm == 10) {
                    this.getSite(res.data.destCityId, res.data.sendSite);
                }
                if (this.$route.meta.title == '新增产品') {
                    this.$store.dispatch("addProduct", {
                        productId: res.data.id,
                        resourceId: -1,
                        productOperatorId: res.data.productOperatorId,
                        productNo: res.data.productNo,
                        productType: res.data.productType,
                        productForm: res.data.productForm
                    });
                } else {
                    this.$store.dispatch("editProduct", {
                        productId: res.data.id,
                        resourceId: -1,
                        productOperatorId: res.data.productOperatorId,
                        productNo: res.data.productNo,
                        productType: res.data.productType,
                        productForm: res.data.productForm
                    });
                }
            } else {
                this.messageError(res.errors[0].message);
            }
        },
        async edit(form) {
            form.id = this.productInfo.productId;
            try {
                let res = await this.http('/galaxyProduct/productPut', form, 'PUT');
                console.log(res);
                if (res.success) {
                    this.$message.success('保存成功');
                    let tabIndex = this.$route.params.tabIndex;
                    console.log(tabIndex);
                    if (this.$route.meta.title == "新增产品") {
                        if (tabIndex < 3) {
                            this.$router.replace({
                                name: "basicInfo",
                                params: {
                                    tabIndex: (parseInt(tabIndex) + 1)
                                }
                            })
                        } else {
                            this.$router.push({
                                name: "resourceManage",
                                params: {
                                    productId: res.data.id,
                                    productOperatorId: res.data.productOperatorId,
                                    productNo: res.data.productNo,
                                    productType: res.data.productType,
                                    productForm: res.data.productForm
                                }
                            })
                        }
                    }

                } else {
                    this.messageError(res.errors[0].message);
                }
            } catch (e) {
                this.messageError();
            }

        },
        // 预订须知
        // 新增一条规则
        changeAddRules() {
            let rulesArr = JSON.parse(JSON.stringify(this.form.refundRules))
            let data = {
                day: 0,
                hour: '00:00',
                hours: '00',
                minutes: '00',
                type: 0,
                cancellationRate: '0',
                isDelete: true,
                disabled: false
            }
            rulesArr.splice((rulesArr.length - 1), 0, data)
            this.form.refundRules = rulesArr
            this.handleInputRules()
        },
        timeFormat(time) {
            let arr = [time.slice(0, 2), time.slice(3, 5)]
            return arr
        },
        // 时间
        changeDateHour(time, indexIdent) {
            let {index} = indexIdent
            let identForm = this.form.refundRules[index]
            identForm.hour = time
            identForm.hours = this.timeFormat(time)[0]
            identForm.minutes = this.timeFormat(time)[1]
            this.handleInputRules()
        },
        // 删除规则
        changeDelRules(index) {
            this.form.refundRules.splice(index, 1)
        },
        // 费率
        handleRate(index) {
            let rulesArr = JSON.parse(JSON.stringify(this.form.refundRules))
            if (rulesArr[index].cancellationRate > 100) rulesArr[index].cancellationRate = 100
            if (rulesArr[index].cancellationRate < 0) rulesArr[index].cancellationRate = 0
            this.form.refundRules = rulesArr
        },
        // 规则排序
        handleInputRules() {
            let rulesArr = JSON.parse(JSON.stringify(this.form.refundRules))
            let {
                day,
                hour,
            } = rulesArr[rulesArr.length - 2]
            rulesArr[rulesArr.length - 1].day = day
            rulesArr[rulesArr.length - 1].hour = hour
            rulesArr[rulesArr.length - 1].hours = this.timeFormat(hour)[0]
            rulesArr[rulesArr.length - 1].minutes = this.timeFormat(hour)[1]
            this.form.refundRules = rulesArr
        }
    },
    //监听模版变量
    watch: {
        // "form":{
        //     handler (val) {
        //         console.log(val);
        //     },
        //     deep: true
        // }
    }

}
</script>
