<style lang="scss" scoped>
    .container-price {
        display: flex;
        background-color: #fff;
        /*padding: 20px 0;*/
        padding-bottom: 20px;
        position: relative;
        height: calc(100vh - 200px);

        .lf-box {
            width: 208px;
            height: 820px;
        }
        .rt-box {
            width: calc(100% - 248px);
            padding: 0 17px;
        }
        .bt-form {
            margin: 20px 0;
            background-color: #f8f8f8;
            padding: 15px 15px 0 15px;
            h2 {
                font-size: 14px;
                margin-bottom: 10px;
                color: #333333;
            }
            input {
                font-size: 18px;
                color: #333333;
            }
            .form-btn {
                width: 250px;
                text-align: right;
                button {
                    width: 82.5px;
                }
            }
        }
    }

    .resource-list {
        margin-right: 12px;
    }

    .resource-list li {
        min-width: 200px;
        cursor: pointer;
        line-height: 20px;
        /*padding-right: 8px;*/
        font-size: 14px;
        color: #909399;
        position: relative;
        border-radius: 0;
        div {
            padding: 10px;
            border-bottom: 1px solid #F0F2F5;
        }
        &:hover, &.selected {
            div {
                /*border-color: rgba(24, 144, 255, 0.1);*/
                border-color: #EAF3FF;
            }
            background: #EAF3FF;
            &:before {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 6px solid #EAF3FF;
                position: absolute;
                right: -5px;
                top: 50%;
                margin-top: -6px;
            }
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                right: 0;
                top: -1px;
                height: 1px;
                background: #EAF3FF;
            }

        }
        &.selected {
            color: #1890FF;
        }

    }

    .lf-box /deep/ {
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    /*.el-scrollbar__bar {*/
    /*display: none;*/
    /*}*/
</style>
<style lang="scss">
    #price-inventory {
        .el-form-item__label {
            color: #333;
        }
        .el-input__inner {
            font-size: 18px;
            color: #333;
            width: 180px;
            /*height: 30px;*/
            &:-ms-input-placeholder {
                font-size: 14px;
            }
            &::-webkit-input-placeholder {
                font-size: 14px;
            }
            &:-moz-placeholder {
                font-size: 14px;
            }

        }
    }

    .container-price {
        .lf-box {
            .el-scrollbar {
                height: 100%;
                -webkit-overflow-scrolling: touch;
                overflow-y: hidden;

            }
        }
        .bt-form {
            .el-form--inline .el-form-item {
                margin-right: 30px;
            }
            .el-input__suffix-inner {
                span {
                    margin-right: 5px;
                }
            }
        }

    }

</style>
<template>
    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont container-price" id="price-inventory" v-loading="loading">

                <div class="lf-box">
                    <el-scrollbar>
                        <ul class="resource-list">
                            <li v-for="(vm,idx) in resourceList"
                                :class="{'selected':selectedIndex===idx}"
                                @click="handleResourceItemClick(vm,idx)"
                                :key="idx">
                                <div style="font-size: 12px">
                                    <p>资源ID：{{vm.resourceNo}}</p>
                                    <p>{{vm.resourceName}}</p>
                                </div>
                            </li>
                        </ul>
                    </el-scrollbar>
                </div>

                <div class="rt-box">
                    <price-calendar
                            @dayClick="handleDateClick"
                            @updateMonth="handleUpdateMonth"
                            :inventory="inventory"
                            ref="calendar"></price-calendar>
                    <div class="bt-form">
                        <h2>设置选中的日期：</h2>
                        <el-form ref="form"
                                 :inline="true"
                                 :model="form"
                                 label-width="70px"
                                 :rules="rules"
                                 label-position="left"
                                 size="small">
                            <el-form-item label="结算价：" prop="settlePrice">
                                <el-input v-model.number="form.settlePrice"
                                          clearable
                                          min="0"
                                          type="number"
                                          placeholder="结算价"
                                          @change="handleIptChange"
                                >
                                    <span slot="suffix">元</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="同行价：" prop="peerPrice">
                                <el-input v-model.number="form.peerPrice" clearable type="number" min="0"
                                          placeholder="同行价">
                                    <span slot="suffix">元</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="直客价：" prop="customerPrice">
                                <el-input v-model.number="form.customerPrice"
                                          @input="handleCustomerPriceChange"
                                          clearable
                                          type="number" min="0"
                                          placeholder="直客价">
                                    <span slot="suffix">元</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="市场价：" prop="marketPrice">
                                <el-input v-model.number="form.marketPrice" clearable type="number" min="0"
                                          placeholder="市场价">
                                    <span slot="suffix">元</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="总库存：" prop="allInventory">
                                <el-input v-model="form.allInventory" clearable type="number" min="0" placeholder="总库存">
                                    <span slot="suffix">件</span>
                                </el-input>
                            </el-form-item>
                            <el-form-item class="form-btn">
                                <el-button @click="handleCancel">取消</el-button>
                                <el-button type="primary" @click="handleSubmit">确定</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-main>
    </div>

</template>

<script type="text/ecmascript-6">
    import PriceCalendar from './priceCalendar.vue'
    import resourceService from '../resourceManage/service'

    export default {
        //定义模版数据
        data() {
            return {
                loading: false,
                params: {
                    productId: "",
                    productNo: "",
                    resourceId: "",
                    resourceNo: ""
                },
                resourceList: [],
                selectedIndex: 0,
                inventory: [],
                initForm: {
                    productId: null,
                    productNo: null,
                    resourceId: null,
                    resourceNo: null,
                    settlePrice: null,
                    marketPrice: null,
                    allInventory: null,
                    peerPrice: null,
                    customerPrice: null
                },
                form: {},
                rules: {
                    settlePrice: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback("请输入结算价")
                                else
                                    callback();
                            }, trigger: 'blur'
                        },
                    ],
                    marketPrice: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback("请输入市场价")
                                else
                                    callback();
                            }, trigger: 'blur'
                        },
                    ],
                    allInventory: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback("请输入总库存")
                                else
                                    callback();
                            }, trigger: 'blur'
                        },
                    ],
                    peerPrice: [
                        {
                            validator: (rule, value, callback) => {
                                console.log(value, this.form.settlePrice);
                                if (!value)
                                    callback("请输入同行价")
                                else if (value < this.form.settlePrice) {
                                    console.log(123123);
                                    callback("同行价应大于结算价")
                                }

                                else
                                    callback()
                            }, trigger: 'blur'
                        }
                    ],
                    customerPrice: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value)
                                    callback("请输入直客价")
                                else if (value < this.form.settlePrice)
                                    callback("直客价不应小于结算价")
                                else if (value < this.form.peerPrice)
                                    callback("直客价不应小于同行价")
                                else
                                    callback()
                            }, trigger: 'blur'
                        }
                    ]
                }
            }
        },
        components: {
            PriceCalendar
        },
        //计算属性
        computed: {},
        //主件被加载完成
        activated: function () {
            if (this.$route.meta.title == "新增产品") {
                this.$store.dispatch("addProduct", {
                    productId: this.$route.params.productId,
                    resourceId: this.$route.params.resourceId,
                    productOperatorId: this.$route.params.productOperatorId,
                    productNo: this.$route.params.productNo,
                    productType: this.$route.params.productType,
                    productForm: this.$route.params.productForm
                });
            } else {
                this.$store.dispatch("editProduct", {
                    productId: this.$route.params.productId,
                    resourceId: this.$route.params.resourceId,
                    productOperatorId: this.$route.params.productOperatorId,
                    productNo: this.$route.params.productNo,
                    productType: this.$route.params.productType,
                    productForm: this.$route.params.productForm
                });
            }
            this.params = this.$route.params;
            this.getResourceList();
            this.resetForm();
            this.loading = false;
        },
        //定义事件方法
        methods: {
            resetForm() {
                this.form = Object.assign({}, this.initForm);
            },
            async getResourceList() {
                this.resourceList = this.sessionStorage.getObject('resources_list');
                if (!this.resourceList || this.resourceList.length === 0) {
                    const ret = await resourceService.getResourcesList(this, this.params.productId);
                    if (ret.success) {
                        this.resourceList = ret.data;
                    } else {
                        this.$message.error('抱歉，资源获取失败，刷新再试。')
                    }
                }
                if (this.resourceList.length > 0) {
                    this.resourceList.forEach((data, idx) => {
                        if (data.id === this.params.resourceId) {
                            this.selectedIndex = idx;
                            this.params.resourceNo = data.resourceNo;
                        }
                    })
                    if (this.$route.params.resourceId == -1) {
                        this.params.resourceId = this.resourceList[0].id;
                        this.params.resourceNo = this.resourceList[0].resourceNo;
                    }
                    this.getInventory();

                }
                return;

            },
            async getInventory() {
                if (this.$refs["calendar"]) {
                    let calendarObj = this.$refs["calendar"].getCalendar();
                    if (calendarObj.type === 1)
                        return;
                    const calendar = calendarObj.data;
                    this.loading = true;

                    const ret = await this.http('/galaxyInventory/getInventory', {
                        resourceId: this.params.resourceId,
                        inventoryMonth: ('' + calendar.year + (calendar.month < 10 ? '0' + calendar.month : calendar.month))
                    }, 'POST', false)
                    if (ret.success) {
                        this.inventory = ret.data;
                    }
                    else {
                        this.$message.error('抱歉，库存获取失败，刷新再试。')
                        this.inventory = [];
                    }
                    this.loading = false;

                }
                return;

            },
            //点击资源项
            handleResourceItemClick(resource, idx) {
                this.selectedIndex = idx;
                this.params.resourceId = resource.id;
                this.params.resourceNo = resource.resourceNo;
                this.getInventory();
                this.$refs["calendar"].clearSelected();
            },
            //取消
            handleCancel() {

            },
            //确定
            handleSubmit() {
                if (this.resourceList.length == 0)
                    return this.messageError('请选择要修改价格的资源');
                // this.$refs["calendar"].validateForm()
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.form.productId = this.params.productId;
                        this.form.productNo = this.params.productNo;
                        this.form.resourceId = this.params.resourceId;
                        this.form.resourceNo = this.params.resourceNo;
                        const dateObj = this.$refs["calendar"].getCalendar();
                        console.log(dateObj.data);
                        if (dateObj.data.length === 0)
                            return;
                        let datas = [];
                        const url = dateObj.type == 0 ? '/galaxyInventory/updateInventory' : '/galaxyInventory/addInventoryByMonth';
                        if (dateObj.type == 0) {
                            let calendar = dateObj.data;
                            const month = calendar.year + (calendar.month < 10 ? ('0' + calendar.month) : '' + calendar.month);
                            this.form.inventoryMonth = month;
                            calendar.days.forEach((row) => {
                                row.forEach((col) => {
                                    if (col.selected) {
                                        let form = JSON.parse(JSON.stringify(this.form));
                                        form.inventoryDay = month + (col.day < 10 ? ('0' + col.day) : '' + col.day)
                                        if (col.inventory)
                                            form.id = col.inventory.id
                                        datas.push(form)
                                    }
                                })
                            })
                        } else {
                            dateObj.data.forEach((data) => {
                                let form = Object.assign({}, this.form);
                                const month = data.month < 10 ? '0' + data.month : data.month;
                                const day = data.day < 10 ? '0' + data.day : data.day;
                                form.inventoryMonth = `${data.year}${month}`
                                form.inventoryDay = `${data.year}${month}${day}`
                                datas.push(form);
                            })
                        }
                        this.loading = false;
                        if (datas.length === 0) {
                            this.$message.error('请先选择日期');
                            return;
                        }
                        if (valid) {
                            this.$confirm('保存后数据将即使生效', '保存设置', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(async () => {
                                this.loading = true;
                                const ret = await this.http(url, datas, 'POST', false);
                                if (ret.success) {
                                    this.$message.success('设置已生效!')
                                    if (dateObj.type == 0)
                                        this.getInventory();
                                }
                                else
                                    this.$message.error('出错了，请稍后重试!')
                                this.loading = false;

                            }).catch(() => {
                            });
                        }
                    }
                })
            },
            //日期被选择
            handleDateClick() {
                this.resetForm();
                const calendar = this.$refs["calendar"].getCalendar().data;
                let inventory = null, count = 0;
                calendar.days.forEach((row) => {
                    row.forEach((col) => {
                        if (col.selected) {
                            count++;
                            if (col.inventory)
                                inventory = col.inventory;
                        }
                    })
                })
                if (count === 1 && inventory) {
                    this.form.settlePrice = inventory.settlePrice;
                    this.form.marketPrice = inventory.marketPrice;
                    this.form.allInventory = inventory.allInventory;
                    this.form.peerPrice = inventory.peerPrice;
                    this.form.customerPrice = inventory.customerPrice;
                }
            },
            handleIptChange() {
            },
            handleUpdateMonth() {
                this.getInventory();
            },
            handleCustomerPriceChange() {
                this.form.marketPrice = Math.ceil(this.form.customerPrice / 0.7)
            }
        },
        deactivated() {
            this.$destroy();
        },
        //监听模版变量
        watch: {}

    }
</script>
